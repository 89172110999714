<div class="displayTable" style="background: url('https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/login%2Fbg-login.png?alt=media&token=3ff2f69b-a641-4e51-b79f-b1a7eca93470'); background-size: cover">
  <div class="displayTableCell">

    <div class="px-logo">
      <a href="https://www.positronx.io" target="_blank">
        <img src="https://www.positronx.io/wp-content/themes/positronx/img/logo-positronx-white.svg" alt="positronX.io - Learn Full Stack Development">
      </a>
    </div>

    <div class="authBlock" style="margin-left: 7%">
      <h3 style="font-size: 18px;margin-left: 100px; margin-top: 43px;">Accedi</h3>
      <div class="formGroup">
        <h3 style="font-weight: 500; font-size: 18px; margin: 0px; color: #4A4A4A; margin-top:240px;">Username</h3>
        <input type="text" style=" border-width:0px; border-bottom-width: 1px; border-color:#E4E4E4; color: black; width: 93%; padding-top: 10px; padding-bottom: 10px;" class="formControl" placeholder="Marco@deweats.com" #userName required>
      </div>

      <div class="formGroup">
        <h3 style="font-weight: 500; font-size: 18px; margin: 0px; color: #4A4A4A; margin-top: 30px;">Password</h3>
        <input type="password" style=" border-width:0px; border-bottom-width: 1px; border-color:#E4E4E4; color: black; width: 93%; padding-top: 10px; padding-bottom: 10px;" class="formControl" placeholder="Password" #userPassword required>
      </div>

      <!-- Calling SignIn Api from AuthService -->
      <div class="formGroup">
        <input type="button" style="background-color: white; border-width: thin; border-color:black; height: 50px; z-index: 20; width: 93%; margin-left: 0%; margin-right: 0%; margin-top: 30px; color:black; font-weight: 500; font-size:22px" class="btn btnPrimary" value="Accedi" (click)="authService.SignIn(userName.value, userPassword.value)">
      </div>

    </div>



  </div>
</div>
