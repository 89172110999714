import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';
import {ModificaPiattoService} from '../../pages/modifica-menu/service/modificaPiatto.service';

/**
 *
 *
 * Card per la modifica del menu
 *
 *
 *
 */
@Component({
  selector: 'menuEditCard',
  styles: [`


    h4{
      margin-bottom: 0px;
    }

    #container {
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }

    .block {
      width: 100%;
      margin: 0px;
      border-color: grey;
      margin-bottom: 2px;
      display: inline-flex;
      background: white;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bolder;
      color: black;
      z-index: 0;

      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

    }

    #store-locator {
      color: black;
    }

    .ingredienti{
      font-size: 16px;
      font-weight: lighter;
      color: grey;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .costo{
      font-size: 16px;
      font-weight: lighter;
      color: black;
      margin-bottom: 10px;
      padding-bottom: 10px;

    }

    .nome{
      padding-top: 10px;
      margin-top: 10px;
    }

    *:focus {
      outline: none !important;
      border:0px solid red;
    }

    ::-webkit-scrollbar{
      display:none
    }

  `],
  template: `

    <div>
      <div id="store-locator" class="block" style="position: relative; z-index: 1; margin-top: -10px; overflow: hidden">
        <!--img class="store" [ngStyle]="" style="background-size: 10% auto;" src="{{this.link}}"-->
        <div>
          <!--div class="nome" style="padding-left: 15px">{{this.nome}}</div>
          <div class="ingredienti" style="padding-left: 15px">{{this.ingredienti}}</div>
          <div class="costo" style="padding-left: 15px">{{this.prezzo}}</div-->
          <!--input type="text" id="fname" name="fname"  style="margin-top: 5px; margin-left: 15px; border-color: white; border: 0px" placeholder="{{this.nome}}">
          <input type="text" id="fname" name="fname"  style="margin-top: 5px; margin-left: 15px; border-color: white; border: 0px" placeholder="{{this.ingredienti}}">
          <input type="text" id="fname" name="fname"  style="margin-top: 5px; margin-left: 15px; border-color: white; border: 0px" placeholder="{{this.prezzo}}"-->
          <div>
            <textarea [(ngModel)]="this.piatto.nome" style="border: 0px; width: 90%; margin-top: 10px" placeholder="{{piatto.nome}}" ></textarea>
          </div>
          <textarea [(ngModel)]="this.piatto.descrizione" style="border: 0px; width: 90%" placeholder="{{piatto.descrizione}}"></textarea>
          <textarea [(ngModel)]="this.piatto.prezzo" style="border: 0px; width: 90%" placeholder="{{piatto.prezzo}} €"></textarea>
        </div>
      </div>
    </div>
  `
})
export class MenuEditCardComponent implements OnChanges{

  @Input() nome ;
  @Input() ingredienti ;
  @Input() link ;
  @Input() prezzo ;

  @Input() piatto ;
  @Input() indexCategoria ;
  @Input() indexPiatto ;

  prezzoSalva;




  constructor(
    // public authService: AuthService,
    private router: Router,
    private modificaPiattoService: ModificaPiattoService
  ) {


  }

  ngOnChanges(changes: SimpleChanges): void {

    console.log('ciauddghd');
    if ( !isNaN(+this.piatto.prezzo))
    {
      this.prezzoSalva = this.piatto.prezzo;
      this.modificaPiattoService.updatePiatto(this.piatto, this.indexPiatto, this.indexCategoria);
    }
    else{
      this.modificaPiattoService.updatePiattoRotto(this.piatto, this.indexPiatto, this.indexCategoria, this.prezzoSalva);

    }


  }

  clicco(): void
  {
    console.log('ciaociaociaociao', this.nome);
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
