
<div class="container2" style="background: white">
  <div class="app" style="padding-bottom: 0px">
    <div (click)="clicco2()" style=" position: relative; margin-left: 25px; margin-top: 8px"><span class="store1" style=" padding: 0px;"></span></div>
    <h1 style="font-size: 24px; margin-top: 14px; margin-left: 25px">Modifica EditMenu</h1>
  </div>
</div>





<mat-accordion style="max-width: 50px">
  <!-- #docregion basic-panel -->
  <!-- #docregion hide-toggle -->

  <div style="padding-top: 80px"></div>
  <mat-expansion-panel hideToggle *ngFor="let categoria of menu, let i = index" style="border: 0px; border-color: white">
    <!-- #enddocregion hide-toggle -->
    <mat-expansion-panel-header>
      <mat-panel-title style="font-weight: 400; font-size: 22px;">
          <button class="btnRemoveCategoria" style="border-radius: 15px; border-width: thin; border-color:#E65934; background-color: white;  height: 30px; width: 30px; margin-top: 2%; color: red; font-size: 16px; margin-right: 10px; padding-left: 12px; margin-left: 10px" (click)="removeCategoria($event, i)"> - </button>
          <textarea style="border: 0px; width: 50%; height: 30px" placeholder="{{categoria.categoria}}"></textarea>
      </mat-panel-title>
      <button class="btnAddPiatto" style="border-radius: 15px; border-width: thin; border-color:#20A453; background-color: white; height: 30px; width: 30px; margin-top: 2%; color:#20A453; padding-left:10px; margin-left: 5px" (click)="addPiatto($event, i)"> + </button>
    </mat-expansion-panel-header>
    <div *ngFor="let menu of categoria.menu, let j = index">
      <button class="btnRemovePiatto" style="position: relative; border-radius: 15px; background-color: red; border: 0px; height: 30px; width: 30px; margin-bottom: -40px; margin-left: -18px; padding-bottom: -10px; z-index: 20; color: white; padding-left: 23px; text-align: center;" (click)="removePiatto($event, i, j)">
        <a style="overflow: hidden; margin-left: -13px; position: relative">
          -
        </a>
      </button>
      <menuEditCard  class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-bottom:30px;"  [nome]="menu.nome" [ingredienti]="menu.descrizione" [link]="menu.link" [prezzo]="menu.prezzo" [piatto]="menu" [indexCategoria]="i" [indexPiatto]="j" ></menuEditCard>
    </div>
  </mat-expansion-panel>
</mat-accordion>


<!--input type="text" id="fname" name="fname"  style="margin-top: 50px; border-color: white; border: 0px; height: 50px" placeholder="Nome Categoria"-->
<button class="btnInserisciCategoria" style="background-color: white; border-width: thin; border-color:#E65934; height: 50px; margin-top: 2%; margin-right: 5px; margin-left: 0px; z-index: 20; width: 100%; margin-left: 0%; margin-right: 0%; margin-top: 0px; color:#E65934;"  (click)="inserisciCategoria()">Inserisci Categoria</button>
<button class="btnSalva" style="background-color: white; border-width: thin; border-color:greenyellow; height: 50px; margin-top: 2%; margin-right: 5px; margin-left: 0px; z-index: 20; width: 100%; margin-left: 0%; margin-right: 0%; margin-top: 0px; color:green;"  (click)="salva()">Salva</button>


