import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'standardCard',
  styles: [`


    .card {
      border: solid 1px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 4px 7px #0000000D;
      box-shadow: 0px 4px 7px #0000000D;
      -webkit-box-shadow: 0px 4px 7px #0000000D;
      -o-box-shadow: 0px 4px 7px #0000000D;
    }


    /* On mouse-over, add a deeper shadow */
    .card:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    /* Add some padding inside the card container */


    ::-webkit-scrollbar{
      display:none
    }

    .app {
      padding: 20px 0;
      display: grid;
      grid-gap: 20px 0;
      grid-template-columns: 20px 1fr 20px;
      align-content: start;
    }

    .app > * {
      grid-column: 2 / -2;
    }

    .app > .full {
      grid-column: 1 / -1;
    }

    .hs {
      display: grid;
      grid-gap: calc(20px / 2);
      grid-template-columns: 10px;
      grid-template-rows: minmax(150px, 1fr);
      grid-auto-flow: column;
      grid-auto-columns: calc(50% - 20px * 2);

      overflow-x: scroll;
      scroll-snap-type: x proximity;
      padding-bottom: calc(.75 * 20px);
      margin-bottom: calc(-.25 * 20px);
    }

    .hs:before,
    .hs:after {
      content: '';
      width: 10px;
    }

    /* Demo styles */

    html,
    body {
      height: 100%;
    }

    body {
      display: grid;
      place-items: center;
      background: #456173;
    }

    ul {
      list-style: none;
      padding: 0;
    }

    .app {
    }


    b{
      text-align: right;
      font-size: 9px;
      vertical-align: 10%;
    }

    p{
      font-size: 9px;
      vertical-align: 1px;
      padding-bottom: 10px;
    }

    .container {
      padding: 20px 15px;
      vertical-align: center;
      border-radius: 0px;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;
    }

    h4{
      margin-bottom: 1px;
    }

  `],
  template: `
    <div class="card center-cropped" (click)="clicco()" style="border-radius: 20px">
      <div class="center-cropped" [ngStyle]="{'background-image': 'url(' + link + ')', 'background-size': 'cover'  }"
           style=" max-height: 180px; height: 180px; width:100%; ">
      <!--img class="card" [src]="link" alt="Avatar" style="width:100%; max-height: 110px;"-->
      </div>
      <div class="container" style="text-align: left;">
        <h4 style="font-size: 20px; font-weight: bold">{{this.nome}}</h4>
      </div>
    </div>
  `
})
export class StandardCardComponent {

  @Input() nome ;
  @Input() descrizione ;
  @Input() link ;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}

  clicco()
  {
    console.log('ciaociaociaociao', this.nome);
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout() {
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
