import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'receiptHeader',
  styles: [`


    /* Demo styles */

    ul {
      list-style: none;
      padding: 0;
    }

    b{
      text-align: right;
      font-size: 15px;
      vertical-align: 90%;
      margin-bottom: 0px;
      margin-top: 0;
    }


    p.unoScontrinoNotScrolled{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-size: 42px;
      font-weight: normal
    }

    p.unoScontrinoScrolled{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-size: 38px; font-weight: normal
    }

    h4.unoScontrinoNotScrolled{
      padding-left: 30px;
      margin-top: 40px;
    }

    h4.unoScontrinoScrolled{
      padding-left: 30px;
      margin-top: 8px;
    }


    p.dueScontrino{
      color: grey;
      font-size: 18px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-weight: lighter;
    }

    p.dueScontrinoScrolled{
      color: grey;
      font-size: 16px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-weight: lighter;
    }

    h4.dueScontrinoNotScrolled{
      padding-left: 30px;
      padding-bottom: 30px
    }

    h4.dueScontrinoScrolled{
      padding-left: 30px;
      padding-bottom: 15px
    }

    p.treScontrino{
      color: grey;
      font-size: 18px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-weight: lighter;
      margin-top: 10px
    }

    p.treScontrinoScrolled{
      color: grey;
      font-size: 16px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-weight: lighter;
      margin-top: 10px
    }

    h4{
      margin-bottom: 0px;
    }

    .block {
      width: 100%;
      height: 80px;
      margin: 0px;
      display: inline-flex;
      background: white;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: black;

    }

    .blocco {
      margin-bottom: 10px;

      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);



    }

    .store {
      background: url(https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fbento-box_1f371.png?alt=media&token=ac831a36-112d-4f49-a115-c7616e3e1251) no-repeat center center;
      position: relative;
      right: -15px;
      height: 130px;
      width: 130px;
      display: inline-block;
      border-radius: 50%;
      margin-top: -140px;
    }

    .dettaglioScontrinoScrolled{
      background: url(https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fbento-box_1f371.png?alt=media&token=ac831a36-112d-4f49-a115-c7616e3e1251) no-repeat center center;
      position: relative;
      right: -15px;
      height: 90px;
      width: 90px;
      display: inline-block;
      border-radius: 50%;
      margin-top: -100px;
    }

    .dettaglioScontrinoNonScrolled{
      background: url(https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fbento-box_1f371.png?alt=media&token=ac831a36-112d-4f49-a115-c7616e3e1251) no-repeat center center;
      position: relative;
      right: -15px;
      height: 130px;
      width: 130px;
      display: inline-block;
      border-radius: 50%;
      margin-top: -140px;
    }

    .div-interno-scrolled {
      margin-right: 16%
    }

    .div-interno-non-scrolled {
      margin-right: 8%
    }

  `],
  template: `

    <div class="blocco">
    <div id="scontrino-id" class="block" style="height: 40%">
      <div id="div-interno-scrolled-scontrino" class="div-interno-non-scrolled">
          <h4 id="h4-unoScontrino" class="unoScontrinoNotScrolled" style=""><p id="p-unoScontrino" class="unoScontrinoNotScrolled" style="">{{this.nome}}</p></h4>
          <h4 style="padding-left: 30px; padding-bottom: 0px"><p class="treScontrino">{{this.luogo}}</h4>
          <h4 id="h4-dueScontrino" class="dueScontrinoNotScrolled" style=""><p class="dueScontrino">{{this.data}}</h4>
        </div>
      <img id="immagine-dettaglio-scontrino" class="dettaglioScontrinoNonScrolled " src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/1e009f84-94db-4ff9-97fb-712b77ce7ee2.jpeg?alt=media&token=3c7b8239-ed07-49e6-aceb-9a06ba994e71">

    </div>
    </div>

  `
})
export class HeaderScontrinoCard {

  @Input() nome ;
  @Input() luogo ;
  @Input() data ;
  @Input() link ;
  @Input() costo ;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('ciaociaociaociao', this.nome);
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
