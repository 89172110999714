import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import {firebase} from '@firebase/app';
import '@firebase/auth';

import {CheckoutService} from '../../shared/services/stripe/checkout.service';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';


@Component({
  selector: 'app-payment-test',
  templateUrl: './payments-test-page.html',
  styleUrls: ['./payments-test-page.css']
})
export class PaymentsTestPageComponent implements OnInit{

  elements = stripe.elements();

  paymentHandler: any = null;

  success = false;

  failure = false;

  card = elements.create('card');



  constructor(private checkout: CheckoutService, private menuService: FirestoreService) {
  }

  ngOnInit(): void {

    this.card.mount('#card-element');

  }

  clickPay(): void{

    // Make a call to the server to create a new
    // payment intent and store its client_secret.


      const clientSecret = this.checkout.makePaymentIntent('pk_test_51KQYmmD235zCa8lIkIQPU4VIL2NLUL2QZiP9nFOqGqupAEIQ0bs74RvoXwS3jLx9SnZz3GdxjPppH2jKUmLlPH6H002ZpZv8V3').subscribe((data: any) => {
        console.log(data);
        const {error: stripeError, paymentIntent} = stripe.confirmCardPayment(
          data.clientSecret,
          {
            payment_method: {
              card: this.card,
              billing_details: {
                // name: nameInput.value,
                name: 'Barbieri Alessandro',

              },
            },
          }
        );
      });

      console.log(`Client secret returned.`);

    // const nameInput = document.querySelector('#name');

    // Confirm the card payment given the clientSecret
    // from the payment intent that was just created on
    // the server.


  }


  makePayment(amount: number) {
    const paymentHandler = (window as any).StripeCheckout.configure({
      key: 'pk_test_51KQYmmD235zCa8lIkIQPU4VIL2NLUL2QZiP9nFOqGqupAEIQ0bs74RvoXwS3jLx9SnZz3GdxjPppH2jKUmLlPH6H002ZpZv8V3',
      locale: 'auto',
      token(stripeToken: any) {
        console.log(stripeToken);
        paymentstripe(stripeToken);
      },
    });

    const paymentstripe = (stripeToken: any) => {
      this.checkout.makePayment(stripeToken).subscribe((data: any) => {
        console.log(data);
        if (data.data === 'success') {
          this.success = true;
        }
        else {
          this.failure = true;
        }
      });
    };

    paymentHandler.open({
      name: 'Coding Shiksha',
      description: 'This is a sample pdf file',
      amount: amount * 100,
    });
  }

  invokeStripe() {
    if (!window.document.getElementById('stripe-script')) {
      const script = window.document.createElement('script');
      script.id = 'stripe-script';
      script.type = 'text/javascript';
      script.src = 'https://checkout.stripe.com/checkout.js';
      script.onload = () => {
        this.paymentHandler = (window as any).StripeCheckout.configure({
          key: '###yourkey###',
          locale: 'auto',
          token(stripeToken: any) {
            console.log(stripeToken);
          },
        });
      };

      window.document.body.appendChild(script);
    }
  }
}
