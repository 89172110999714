import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MenuService} from '../../shared/services/firestore/menu.service';
import {CategoriesModel} from '../../shared/models/menu/categories.model';
import {DishModel} from '../../shared/models/menu/dish.model';
import {notifySnackBar} from './snackBar/notifySnackBar';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RistoranteModel} from '../../shared/models/menu/ristorante.model';
import {ModificaPiattoService} from './service/modificaPiatto.service';
import * as uuid from 'uuid';


/**
 * @title Basic expansion panel
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'expansion-overview-example',
  templateUrl: './modifica-menu.html',
  styleUrls: ['./modifica-menu.css'],
  providers: [
    MenuService,
    {
      provide: 'ristorante',
      useValue: 'menu',
    },
    {
      provide: 'raccolta',
      useValue: 'ristoranti',
    },
  ],
})
export class InsertMenuComponent implements OnInit {
  panelOpenState = false;

  ristorante = 'ciao';

  nomePiatto = 'Crudo';

  durationInSeconds = 2;

  menu = new Array();

  dati = new RistoranteModel();

  // tslint:disable-next-line:variable-name
  constructor( private router: Router, private menuService: MenuService, private _snackBar: MatSnackBar, private route: ActivatedRoute, private modificaPiattoService: ModificaPiattoService ) {

    this.route.params.subscribe(params => {
        console.log(params);
        if (params.ristorante) {
          this.ristorante = params.ristorante;
        }
      }
    );

    this.modificaPiattoService.emitter.subscribe(
      (piatto) => {
        console.log("piatto: ", piatto);
        console.log("dati: ", this.dati);

        this.dati.menu[piatto.indexCategoria].menu[piatto.indexPiatto] = piatto.piatto;

        console.log("dati cambiati: ", this.dati);

      }
    );

    this.retriveMenu();
  }

  ngOnInit(): void {
  }

  retriveMenu(): void {

    this.menuService.getDoc(this.ristorante).get().subscribe(
      data => {
        if (data.data() !== undefined)
        {

          this.dati = data.data();
          console.log('menuCard: ', this.menu);
          console.log('ho trovato: ', data.data().menu);

          let i = 0;
          for (const x in data.data().menu) {
            this.menu.push(data.data().menu[i]);
            i = i + 1;
          }
          // @ts-ignore
          // this.menuCard = CategoriesModel[data.data];
          // @ts-ignore
          this.menu.concat(data.data().menu);
          // this.menuCard = data.data();
        }
      }
    );

  }


  inserisciCategoria(): void{

    const categoria: CategoriesModel = new CategoriesModel();
    categoria.category = 'Categoria';
    const piatti = new Array();
    categoria.menu = piatti;
    console.log('il menuCard è\'', this.menu);

    this.menu.push(
      {
        categoria: 'Categoria',
        menu: []
      }
    );

  }

  clicco(): void{
    this.router.navigate(['/tavoli']);
  }

  clicco2(): void{
    this.router.navigate(['/menuCard']);
  }

  addPiatto(event, index): void{
    event.stopPropagation();

    const piatto: DishModel = new DishModel();
    piatto.nome = 'Nome Piatto';
    piatto.descrizione = 'Ingredienti belli';
    piatto.prezzo = 1;
    piatto.id = Date.now();

    this.menu[index].menu.push({
      nome: 'Nome Piatto',
      descrizione: 'Ingredienti belli',
      prezzo: 1,
      id: Date.now(),
    });

    console.log('add piatto');
  }

  addCategoria(event): void{
    event.stopPropagation();

    console.log('add categoria');
  }

  removePiatto(event, indexCategoria, indexPiatto): void{
    event.stopPropagation();

    this.removePiattoItem(indexCategoria, indexPiatto);

    console.log('remove piatto');
  }

  removeCategoria(event, index): void{
    event.stopPropagation();
    this.removeCategoriaItem(index);

    console.log('remove catgeroria');
  }

  removeCategoriaItem(index): void {
    if (index > -1) {
      this.menu.splice(index, 1);
    }
  }

  removePiattoItem(indexCategoria, indexPiatto): void {
    if (indexCategoria > -1) {
      if (indexPiatto > -1) {
        this.menu[indexCategoria].menu.splice(indexPiatto, 1);
      }    }
  }

  salva(): void{
    console.log(this.menu);
    console.log(this.menu);

    this.dati.menu = this.menu;

    this.menuService.delete( this.ristorante);

    // this.menuService.create(this.menuCard, this.ristorante);
    this.menuService.create(this.dati, this.ristorante);

    this.openSnackBar();
    // this.router.navigate(['/menuCard']);
  }


  changingCategoria(event: any, i: any): void{
    this.menu[i].categoria = event.target.value;
    console.log(this.menu);
  }

  modificaNomePiatto(event: any, i: any, j: any): void{
    this.menu[i].menu[j].nome = event.target.value;
    console.log(this.menu);
  }

  modificaIngredientiPiatto(event: any, i: any, j: any): void{
    this.menu[i].menu[j].ingredienti = event.target.value;
    console.log(this.menu);
  }

  modificaPrezzoPiatto(event: any, i: any, j: any): void{
    this.menu[i].menu[j].prezzo = event.target.value;
    console.log(this.menu);
  }



  openSnackBar(): void {
    this._snackBar.openFromComponent(notifySnackBar, {
      duration: this.durationInSeconds * 1000,
    });
  }

}


