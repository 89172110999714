import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';
import {dettaglioMenuSelezioneService} from '../../pages/dettaglio-menu/service/dettaglio-menu-selezione-service';

/**
 *
 * card per la personalizzazione del piatto
 *
 *
 */
@Component({
  selector: 'allergyCard',
  styles: [`


    .card-account {
      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 1px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 120px;
      margin: 10px;
    }

    /* On mouse-over, add a deeper shadow */
    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    /* Add some padding inside the card container */



    /* Demo styles */

    ul {
      list-style: none;
      padding: 0;
    }

    b{
      text-align: right;
      font-size: 15px;
      vertical-align: 90%;
      margin-bottom: 0px;
      margin-top: 0;
    }

    p{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    p.due{
      font-size: 15px;
      vertical-align: 0px;
      padding-bottom: 0px;
      font-weight: lighter;
    }

    p.tre{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-weight: lighter;
    }

    .containerScontrino {
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 5px;
      max-height: 50px;
      border-radius: 0px;
      horiz-align: left;
      float: left;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;

    }

    h4{
      margin-bottom: 0px;
    }

    .header {
      display: inline-block;
      width: 100%;
      background: white;
    }
    .playerOne {
      float: right;
    }
    .playerTwo {
      float: left;
    }

    /* The container */
    .containercheckbox {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    .containercheckbox input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      border-style: solid;
      border-color: #eee;
      border-width: 1px;
      position: absolute;
      border-radius: 70%;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #fff;
    }



    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #20A453;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 9px;
      top: 4px;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    #container {
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }

    .block {
      width: 100%;
      height: 80px;
      margin: 0px;
      margin-bottom: 1px;
      display: inline-flex;
      background: white;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: black;

      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);


    }

    #store-locator {
      color: black;
    }

  `],
  template: `

    <div id="store-locator" class="block" (click)="clicco()">
      <div class="container" style="text-align: left">
        <label class="containercheckbox" style=" margin-left: -5px">
          <input type="checkbox" [(ngModel)]="selezione"
                 [ngModelOptions]="{standalone: true}"
                 disabled="true"
          >
          <span class="checkmark"></span>
        </label>
        <h4 style="font-weight: lighter; margin-top: -8px; margin-left: 30px;"><p>{{this.nome}}</p></h4>
      </div>
    </div>
  `
})
export class AllergyCardComponent implements OnChanges{

  @Input() nome ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;
  @Input() quantita;
  @Input() ifCosto;
  @Input() nominativo;
  @Input() indice;

  @Input() selezione;

  constructor(
    // public authService: AuthService,
    private router: Router,
    private DettaglioMenuSelezioneService: dettaglioMenuSelezioneService,
    private changeDetection: ChangeDetectorRef
  ) {

    /*
    this.DettaglioMenuSelezioneService.resultList$
      .subscribe(resultList => {
        if (this.nominativo === 'MenuPrimario')
        {
          console.log('fhfghchchcchchchgchgchgch ', this.indice, resultList);

          if (this.indice === resultList)
          {
            this.selezionato = true;
            console.log('This is the updated resultList: ', resultList);
            this.changeDetection.detectChanges();
          }
          else {
            this.selezionato = false;
          }
        }

      });

     */
  }

  ngOnChanges(changes: SimpleChanges) {

    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values

  }


  clicco(): void
  {
    //this.selezionato = !this.selezionato;
    console.log('porcodio');
  }
  /**
   * Simulate logout
   */



}
