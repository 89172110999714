import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 *
 *
 * componente per i dettagli del ristorante nel menu
 *
 *
 *
 */
@Component({
  selector: 'menuContainer',
  styles: [`

    .options .wrap {
      padding: 1rem;
      text-align: center;
      background-color: white;
      border-radius: 12px;

      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 1px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 2px 4px RGB(210, 210, 210);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);
      align: center;
      max-width: 400px;
    }

    .row{
      display: block;
    }

    .container-fluid{
      margin-top: -5rem;
      padding-left: 20%;
      padding-right: 20%;

    }

    @media only screen and (max-width: 600px) {
      .container-fluid{
        padding-left: 15%;
        padding-right: 15%;
      }
    }

    @media only screen and (max-width: 290px) {
      .store3{
        margin-top: 5px;
      }

      .store4{
        margin-top: 20px;
      }

      .store5{
        margin-top: -5px;
      }
    }

    .blockico {
      margin-top: -50px;
      border-radius: 50px;
      height: 60px;
      width: 60px;

      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 1px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 2px 4px RGB(210, 210, 210);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);
    }

    h4{
      margin-bottom: 0px;
    }

    p{
      font-size: 13px;
      text-align: center;
      padding-left: 10px;
      font-weight: bold;
      margin-top: -14px;
      line-height: 13px;
      margin-bottom: 0px;
    }

    .store {
      background: no-repeat center;
      position: relative;
      right: 2px;
      background-size: 5px 5px;
      height: 70px;
      width: 70px;
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: -15px;
    }

    .store1 {
      margin-top: 10px;
      background: no-repeat center;
      position: relative;
      right: -4px;
      background-size: 5px 5px;
      height: 50px;
      width: 50px;
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      margin-top: 2px;
    }



  `],
  template: `

    <div *ngIf="!scannerEnabled" class="container-fluid" >
      <div class="row options">
        <div class="col-xs-12">
          <div class="wrap">
            <a>
              <img class="blockico"  style="background-color: white" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/logolavalletta.png?alt=media&token=59fc56ed-2e01-4146-9e8d-bd4d1bbceb43">
              <h4 style="font-size: 14px; vertical-align: 0px; padding-bottom: 0px; font-weight: bolder; margin-bottom: 0px">{{title}}</h4>
              <h4 style="font-size: 14px; vertical-align: 0px; padding-bottom: 0px; font-weight: lighter;"></h4>
              <div class="store store3">
                <span class="store1" [ngStyle]="{background: 'url(' + this.link2 + ') no-repeat center', backgroundSize: '60%'}"></span>
                <p style="padding-left: 2px">Chiama Cameriere</p>
              </div>
              <div class="store store4">
                <p style="margin-bottom: -18px; font-size: 16px; margin-right: 2px">{{tavolo}}</p>
                <span class="store1" [ngStyle]="{background: 'url(' + this.link3 + ') no-repeat center', backgroundSize: '60%'}"></span>
                <p>Numero Tavolo</p>
              </div>
              <div class="store store5">
                <span class="store1" [ngStyle]="{background: 'url(' + this.link4 + ') no-repeat center', backgroundSize: '60%'}"></span>
                <p>Coperto {{coperto}}</p>
              </div>
              <!--img rel="preload" class="" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2Fqrcode.png?alt=media&token=9fc9a7ae-e9b8-4108-b2f3-24845609e10d" style="margin-bottom: 15px"-->
            </a>
          </div>
        </div>
      </div>
    </div>

  `
})
export class MenuContainerComponent {

  @Input() link = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/logolavalletta.png?alt=media&token=59fc56ed-2e01-4146-9e8d-bd4d1bbceb43' ;
  @Input() title ;
  @Input() subtitle ;
  @Input() tavolo ;
  @Input() coperto ;
  @Input() scannerEnabled;
  public link2 = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/menu%2Fwaiter_4002%402x.png?alt=media&token=01809448-e687-4b6d-b76b-4ac697ae172c';
  public link3 = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/menu%2Ftable_4090%402x.png?alt=media&token=99897b5e-d139-44b5-9f01-ea3b1e73c5b7';
  public link4 = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/menu%2Fknife-fork_39057%402x.png?alt=media&token=a102f695-dce9-47de-b99c-405829c81815';


  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('ciaociaociaociao');
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
