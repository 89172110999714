<profileCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; z-index: 0;" nome="Offerte" colore="white" titolo="black" height="8vh"></profileCard>


<div class="container2" (scroll)="onWindowScroll($event);">

  <!--h1>TV MAZE</h1>
  <form #f="ngForm" (submit)="submit(f)">
    <input type="text" ngModel name="text" required="required" placeholder="Search series">
    <button type="submit">Send</button>
  </form>


  <li *ngFor="let series of result">
    {{series.show.name}}
    <img [src]="series.show.image?.medium" alt="" width="100">
    <div *ngIf="!series.show.image">NO FOTO</div>
  </li-->

  <!--router-outlet></router-outlet-->


  <div class="container" style="padding-top: 20px;">

    <div class="categories-content">

      <ul class="hs"  >
        <categoriesCard *ngFor="let cathegory of links" [link]="cathegory"></categoriesCard>

      </ul>
    </div>


  </div>



  <div class="container" style="" *ngFor="let ristorante of ristoranti" style="margin-top: 20px; padding-bottom: 20px">

    <div class="row" style="padding-top: 0px; margin-bottom: 20px">
      <standardCard (click)="this.router.navigate([ 'Lago-Valletta' + '/menu']);" class="col col-md"  nome="Pizza + bibita a 5€" [descrizione]="ristorante.descrizione"  link="https://pizzeria2000.it/wp-content/uploads/2020/11/banner-coca-cola-1-1024x683.jpg"></standardCard>
    </div>

    <div class="row" >
      <standardCard (click)="this.router.navigate([ristorante.nome + '/menu']);" class="col col-md" [nome]="ristorante.nome" [descrizione]="ristorante.descrizione" [link]="ristorante.link"></standardCard>
    </div>

  </div>

  <!--div class="container" style="padding-top: 20px">
    <div class="row" style="margin: 0px 0px 0px">
      <p style="text-align: left; width:80%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis;">Ristoranti popolari vicini</p>
      <p style="text-align: right; width:20%;  display: inline-block; background-color: #fbfbfb; border: none; color: #E95237; font-size: 13px; padding-right: 0px">Tutto</p>
    </div>
    <div class="row" style="padding-top: 0px; margin-top: 0px">
      <standardCard class="col-6 col-md-4" style="padding-right: 6px; margin: 0px" [nome]="currentItem" [descrizione]="currentDescription" link="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f"></standardCard>
      <standardCard class="col-6 col-md-4" style="padding-left: 6px"  [nome]="currentItem" [descrizione]="currentDescription"  link="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/1e009f84-94db-4ff9-97fb-712b77ce7ee2.jpeg?alt=media&token=3c7b8239-ed07-49e6-aceb-9a06ba994e71"></standardCard>
    </div>
    <div class="row" style="padding-top: 12px">
      <standardCard class="col-12 col-md-8" [nome]="currentItem" [descrizione]="currentDescription" link="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/d5f6b64a-81c8-41e1-a36a-018476345782.jpeg?alt=media&token=2b40ef69-6d9e-4634-925b-6e5c98cc08a0"></standardCard>
    </div>


  </div>

  <div class="container" style="padding-top: 20px">
    <div class="row" style="margin: 0px 0px 0px">
      <p style="text-align: left; width:80%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis;">Ristoranti popolari vicini</p>
      <p style="text-align: right; width:20%;  display: inline-block; background-color: #fbfbfb; border: none; color: #E95237; font-size: 13px; padding-right: 0px">Tutto</p>
    </div>
    <div class="row" style="padding-top: 0px; margin-top: 0px">
      <standardCard class="col-6 col-md-4" style="padding-right: 6px; margin: 0px" [nome]="currentItem" [descrizione]="currentDescription" link="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f"></standardCard>
      <standardCard class="col-6 col-md-4" style="padding-left: 6px"  [nome]="currentItem" [descrizione]="currentDescription"  link="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/1e009f84-94db-4ff9-97fb-712b77ce7ee2.jpeg?alt=media&token=3c7b8239-ed07-49e6-aceb-9a06ba994e71"></standardCard>
    </div>
    <div class="row" style="padding-top: 12px">
      <standardCard class="col-12 col-md-8" [nome]="currentItem" [descrizione]="currentDescription" link="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/d5f6b64a-81c8-41e1-a36a-018476345782.jpeg?alt=media&token=2b40ef69-6d9e-4634-925b-6e5c98cc08a0"></standardCard>
    </div>


  </div-->

</div>
