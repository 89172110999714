import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'categoriesCard',
  styles: [`

    .card {
      border-radius: 25px;
      border: solid 1px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 3px 7px #0000000D;
      box-shadow: 0px 3px 7px #0000000D;
      -webkit-box-shadow: 0px 3px 7px #0000000D;
      -o-box-shadow: 0px 3px 7px #0000000D;
    }

    .card:hover {
      box-shadow: 4px 8px 0 rgba(0,0,0,0.2);
    }

    .center-cropped {
    }

    .center {
      display: block;
      margin: auto;
      width: 100%;
      padding: 27%;
    }

  `],
  template: `
    <!--div-- class="card center-cropped" (click)="clicco()">
      <div class="center-cropped" [ngStyle]="{'background-image': 'url(' + link + ')'}"
           style=" max-height: 110px; height: 110px; width:100%;">
      </div-->
      <img class="card center" [src]=link (click)="clicco()" >
    <!--/div-->
  `
})
export class categoriesCard {

  @Input() link ;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco()
  {
    console.log('ciaociaociaociao');
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout() {
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
