import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {RouterModule} from '@angular/router';
import { Scontrini } from './pages/scontrini/scontrini';
import {AppRoutingModule} from './router.module';
import {NavBarComponent} from './component/navbar/navbar.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {StandardCardComponent} from './component/standardCard/standardCard.component';
import {Account} from './pages/account/account';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatButtonModule} from '@angular/material/button';
import {categoriesCard} from './component/categoriesCard/categoriesCard.component';
import {ReceiptComponent} from './component/receipt/receipt.component';
import {AccountCardComponent} from './component/accountCard/accountCard.component';
import {ProfileCardComponent} from './component/profileCard/profileCard.component';
import {Categorie} from './pages/categorie/categorie';
import {CategorieCard} from './component/categoriesCard/cardCategorie.component';
import {HeaderScontrinoCard} from './component/receiptHeader/receiptHeader.component';
import {ReceiptHeaderCompactComponent} from './component/receiptHeaderCompact/receiptHeaderCompact.component';
import {ReceiptOrderCardComponent} from './component/receiptOrderCard/receiptOrderCard.component';
import {DettaglioScontrinoFinaleCard} from './component/receiptItemCard/cardDettaglioScontrinoFinale.component';
import {QRCodeScannerComponent} from './component/QRCodeScanner/QRCodeScanner.component';
import {Menu} from './pages/menu/menu';
import {MenuContainerComponent} from './component/menuContainer/menuContainer.component';
import {DettaglioMenu} from './pages/dettaglio-menu/dettaglio-menu';
import {DettagliOrdinazioneMenuCard} from './component/menuOrderCard/cardDettagliOrdinazioneMenu.component';
import {DettagliOrdinazioneMenuCard2} from './component/menuOrderCard/cardDettagliOrdinazioneMenu2.component';
import {MenuItemHeaderComponent} from './component/menuItemHeader/menuItemHeader.component';
import {MenuCardComponent} from './component/menuCard/menuCard.component';
import {SignIn} from './pages/sign-in/sign-in';
import {AngularFireModule} from '@angular/fire';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {environment} from '../environments/environment';
import {environmentFirebase} from '../environments/environment';
import {AuthService} from './shared/services/auth.service';
import {AuthGuard} from './shared/guard/auth.guard';
import {MatExpansionModule} from '@angular/material/expansion';
import {MenuEditCardComponent} from './component/menuEditCard/menuEditCard.component';
import {PreferenzeAlimentari} from './pages/preferenze-alimentari/preferenze-alimentari';
import {DettagliOrdinazioneMenuCard3} from './component/menuOrderCard/cardDettagliOrdinazioneMenu3.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {PaymentsTestPageComponent} from './pages/payments-test-page/payments-test-page';
import * as Stripe from 'stripe';
import {ScansionaTavolo} from './pages/scansiona-tavolo/scansiona-tavolo';
import {Home} from './pages/home/home';
import {Scontrino} from './pages/scontrino/scontrino';
import {AngularFireAnalyticsModule} from '@angular/fire/analytics';
import {InsertMenuComponent} from './pages/modifica-menu/modifica-menu';
import {cardDettaglioScontrino} from './component/receiptItemCard/cardDettagliScontrino.component';
import {MenuService} from './shared/services/firestore/menu.service';
import {AllergyCardComponent} from './component/allergyCard/allergyCard.component';
import {MenuDetailCardComponent} from './component/menuDetailCard/menuDetailCard.component';
import {Carrello} from './pages/carrello/carrello';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MetodoPagamento} from "./pages/metodo-pagamento/metodoPagamento";
import {PaymentMetodCard} from "./component/paymentMetodCard/paymentMetodCard";
import {paymentHeader} from "./component/paymentHeader/paymentHeader.component";
import {Recensione} from "./pages/recensione/recensione";


@NgModule({
  declarations: [
    AppComponent,
    Home,
    Scontrini,
    NavBarComponent,
    StandardCardComponent,
    Account,
    categoriesCard,
    ReceiptComponent,
    Carrello,
    Recensione,
    ReceiptOrderCardComponent,

    AccountCardComponent,
    ProfileCardComponent,

    QRCodeScannerComponent,
    Menu,
    MenuContainerComponent,
    MenuCardComponent,
    MenuDetailCardComponent,


    Categorie,
    CategorieCard,

    HeaderScontrinoCard,


    ReceiptHeaderCompactComponent,

    Scontrino,
    cardDettaglioScontrino,
    DettaglioScontrinoFinaleCard,


    DettaglioMenu,
    DettagliOrdinazioneMenuCard,
    DettagliOrdinazioneMenuCard2,
    DettagliOrdinazioneMenuCard3,
    MenuItemHeaderComponent,

    SignIn,
    InsertMenuComponent,

    MenuEditCardComponent,
    PreferenzeAlimentari,
    PaymentsTestPageComponent,

    ScansionaTavolo,


    AllergyCardComponent,
    MetodoPagamento,
    PaymentMetodCard,
    paymentHeader,


  ],
  imports: [

    AngularFireModule.initializeApp(environmentFirebase.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,

    AngularFireAnalyticsModule,

    ReactiveFormsModule,


    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatButtonModule,
    MatExpansionModule,

    MatSnackBarModule,
      NgbModule,


  ],
  providers: [AuthGuard,
    { provide: LOCALE_ID, useValue: 'it-IT' }, // "en-US"
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
