

<section style="background-color: white">

<div class="container2" style="transition: 1000ms ease; height: 60px; background-color: white; position: relative; position: -webkit-sticky; /* Safari */ position: sticky; top: 0px; z-index: 99;" >
  <div class="app" style="padding-bottom: 0px">
    <div (click)="clicco2()" style=" position: relative; margin-left: 25px; margin-top: 8px"><span class="backNero" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2F507257.png?alt=media&token=f4f84beb-eb17-450d-8d3e-4fa1c7b514dd'}" style=" padding: 0px;"></span></div>
    <h1 style="font-size: 18px; margin-top: 14px; width: 100%; text-align: center; color: black; font-weight: bold;">il tuo carrello</h1>
  </div>
</div>

  <h1 style="font-size: 22px; margin-top: 14px; width: 100%; text-align: center; color: black; font-weight: bold;">{{carrelloTmp.ristorante}}</h1>

  <div class="line"></div>



<div>
  <div class="container2NotScrolled" id="lista-scontrino-dettaglio" style="pointer-events: auto; margin-top: 15px">
    <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let scontrino of temp">
      <receiptOrderCard1 class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [messaggio1]="scontrino.messaggio1" [messaggio2]="scontrino.messaggio2" [luogo]="scontrino.luogo" [link]="scontrino.link" [costo]="scontrino.prezzo" ></receiptOrderCard1>
    </div>
  </div>
</div>


  <!--div>
    <p style="text-align: left; width:80%; display: inline-block; background-color: white; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; color: grey; margin-top: 20px; margin-bottom: 0px; margin-left: 20px; font-weight: normal">Dettagli Ordinazione.</p>
    <div class="container2NotScrolled" id="lista-scontrino-dettaglio" style="pointer-events: auto; margin-top: 15px">
      <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let piatto of carrelloTmp.piatti">
        <receiptOrderCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="piatto.nome" [link]="piatto.link" [costo]="calcolaPrezzo(piatto)" [quantita]="piatto.quantita" ></receiptOrderCard>
      </div>
    </div>
  </div-->




  <div class="container menu" id="menuListaPiatti">
    <p style="text-align: left; width:80%; display: inline-block; background-color: white; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; color: grey; margin-top: 20px; margin-bottom: 0px; margin-left: 20px; font-weight: normal">Dettagli Ordinazione.</p>
    <div style="pointer-events: auto; margin-top: 15px">
      <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let piatto of carrelloTmp.piatti">
        <button *ngIf="piatto.id == piatto.id"  class="detail-button minus-button">-</button>

        <menuDetailCard *ngIf="piatto.id == piatto.id" class="" style="width: 100%; padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-bottom:0px; " [nome]="piatto.nome" [ingredienti]="piatto.descrizione" [link]="piatto.link" [prezzo]="calcolaPrezzo(piatto)" [quantita]="piatto.quantita" [user]=user [piatto]=piatto [carrello]=true ></menuDetailCard>

        <button *ngIf="piatto.id == piatto.id" class="detail-button plus-button">+</button>      </div>
    </div>
  </div>



<!--div *ngFor="let piatto of carrelloTmp.piatti, let i = index"  >
   <p #cacaca id="{{i}}" style="text-align: left; max-width: 95%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 28px; padding-left: 0px; margin: 0px; margin-top:20px; margin-left: 4%; white-space: normal; word-break: normal; word-wrap: break-word;  overflow-wrap: break-word; hyphens: auto; position: sticky; ">{{categoria.categoria}} <t *ngIf="categoria.prezzo"> {{categoria.prezzo}} € </t> <t *ngIf="categoria.persone" style="font-weight: normal; font-size: 24px;"> <br> ( minimo {{categoria.persone}} persone ) </t></p>
   <div class="container menu" id="menuListaPiatti">
  <div class="row" style=" padding-left: 0px; margin-bottom: 0px" *ngFor="let piatto of categoria.menu" (click)="clickPiatto(piatto.nome)">
    <menuCard *ngIf="piatto.prezzo" class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-bottom:0px;"  [nome]="piatto.nome" [ingredienti]="piatto.descrizione" [link]="piatto.link" prezzo="INCLUSO" ></menuCard>
    <menuCard *ngIf="!piatto.prezzo" class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-bottom:0px;"  [nome]="piatto.nome" [ingredienti]="piatto.descrizione" [link]="piatto.link" [prezzo]="piatto.prezzo" ></menuCard>

    <div1 *ngFor="let piattoCarrello of carrelloTmp.piatti, let indexCarrello = index" style="margin-top: 0px; width: 100%">


      <button (click)="rimuoviElementoCarrello(indexCarrello)" *ngIf="piattoCarrello.nome == piatto.nome"  class="detail-button minus-button">-</button>

      <menuDetailCard *ngIf="piattoCarrello.nome == piatto.nome" class="" style="padding-left: 0px; margin-left: 0px; padding-right: 0px; margin-bottom:0px; margin-top: 10px; padding-bottom: 10px"  [nome]="piattoCarrello.nome" [ingredienti]="piattoCarrello.descrizione" [link]="piatto.link" [prezzo]="getPrezzo(indexCarrello)" [quantita]="piattoCarrello.quantita" [user]=user [piatto]=piatto ></menuDetailCard>

      <button (click)="aggiungiElementoCarrello(indexCarrello)" *ngIf="piattoCarrello.nome == piatto.nome" class="detail-button plus-button">+</button>
    </div1>


  </div>
</div>
</div-->

  <div class="container2NotScrolled" style="pointer-events: auto; margin-top: 15px">
    <div class="row" style="padding-top: 0%; padding-left: 0px">
      <dettaglioScontrinoFinaleCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="this.nome" [luogo]="this.luogo"  [costo]="this.tot" ></dettaglioScontrinoFinaleCard>
    </div>
  </div>

  <div class="container2NotScrolled" style="pointer-events: auto; margin-top: 1px">
    <div class="row" style="padding-top: 0%; padding-left: 0px">
      <dettaglioScontrinoFinaleCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="this.nome2" [luogo]="this.luogo" [costo]="calcolaMancia()" [percentuale]="this.percentuale2" ></dettaglioScontrinoFinaleCard>
    </div>
  </div>


  <div id="store-locator" class="block" >
    <div class="container" style="text-align: left">
      <h4 style="margin-top: 10px; font-size: 16px"><b>Metodi di pagamento</b></h4>
    </div>
    <button style="width: 20%; border-radius: 15px; margin-right: 15px; border: 0px" (click)="navigateToMetodoPagamento()"><p style="font-weight: lighter; margin: 4px; padding: 0px; font-size: 16px">Modifica</p></button>
  </div>

  <button mat-fab color="accent"
          style="z-index: 100; width: 85%; border-radius: 15px; display: flex; align-items: center; justify-content: center;"

          class="carrello-button"
          [class.mat-elevation-z2]="!isOver"
          [class.mat-elevation-z8]="isOver"
          (mouseover)="isOver = true"
          (mouseleave)="isOver = false"
  >
    <span style=" margin-right: 0px; font-size: 15px">paga {{tot + calcolaMancia()}}€</span>
    <i class="material-icons"  style=" transform: scale(1.8, 1.8); margin-right: auto;"></i>
  </button>


</section>


