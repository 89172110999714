import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';
import {dettaglioMenuSelezioneService} from '../../pages/dettaglio-menu/service/dettaglio-menu-selezione-service';


@Component({
  selector: 'PaymentMetodCard',
  styles: [`
    .block {
      width: 100%;
      height: 70px;

      display: inline-flex;
      background: white;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: black;

      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);
    }

    .img-card{
      width: 100%;
      float: left;
    }
  `],
  template: `
      <div  class="block" [ngSwitch]="this.nome">
        <div *ngSwitchCase="'Apple Pay'" style="width: 10%"><img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2Fapple-pay.jpeg?alt=media&token=202b1745-4fbf-4900-9b9b-62f156c86bad" class="img-card"></div>
        <div *ngSwitchCase="'PayPal'" style="width: 10%"><img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2Fpaypal.jpeg?alt=media&token=6c818c88-68e7-4d7d-9a68-45119ef6d5fd" class="img-card"></div>
        <div *ngSwitchCase="'**** 3812'" style="width: 10%"><img src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2Fmaster-card.jpeg?alt=media&token=09e43ef0-0922-4ded-883e-fa703ff08958" class="img-card"></div>

        <div style="padding-left: 15px; width: 75%">{{this.nome}}</div>

        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14.5" viewBox="0 0 9.051 16" style="float: right; width: 15%;">
          <g id="arrow_back" data-name="arrow back" transform="translate(9.051 16) rotate(-180)">
            <path id="Shape" d="M9.051,1.4,7.7,0,0,8l7.7,8,1.348-1.4L3.007,8Z"/>
          </g>
        </svg>
    </div>
  `
})
export class PaymentMetodCard implements OnChanges{

  @Input() nome ;

  constructor(
    // public authService: AuthService,
    private router: Router,
  ) {

  }
  ngOnChanges(changes: SimpleChanges) {

  }
}
