import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'receipt',
  styles: [`


    .card-scontrino {
      border-radius: 13px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
    }

    /* On mouse-over, add a deeper shadow
    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }*/

    /* Add some padding inside the card container */



    /* Demo styles */

    ul {
      list-style: none;
      padding: 0;
    }

    b{
      text-align: right;
      font-size: 15px;
      vertical-align: 90%;
      margin-bottom: 0px;
      margin-top: 0;
    }

    p{
      font-size: 15px;
      vertical-align: 100%;
      margin-bottom: 0px;
      text-transform: capitalize;
    }

    p.due{
      font-size: 15px;
      vertical-align: 0px;
      font-weight: lighter;
    }

    p.tre{
      font-size: 15px;
      vertical-align: 100%;
      font-weight: lighter;
    }

    .containerScontrino {
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 5px;
      max-height: 50px;
      border-radius: 0px;
      horiz-align: left;
      float: left;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;

    }

    h4{
      margin-bottom: 0px;
    }

    .right {
      float: right;
      position: static;
    }

    .left {
      float: left;
    }

    .header {
      display: inline-block;
      width: 100%;
      background: white;
    }
    .playerOne {
      float: right;
    }
    .playerTwo {
      float: left;
    }

  `],
  template: `

    <div class="header card-scontrino shadow-sm" >
    <div class="containerScontrino playerTwo" style="padding-left: 5%">
            <p >{{this.nome}}</p>
            <p class="tre">{{this.luogo}}</p>
            <p class="due">{{this.costo}} €</p>
          </div>
        <div class="center-cropped right playerOne" [ngStyle]="{'background-image': 'url(' + link + ')', 'background-size': 'cover'  }" style=" max-height: 87px; height: 87px; width:23%; border-bottom-right-radius: 13px; border-top-right-radius: 13px">
        </div>
      </div>

  `
})
export class ReceiptComponent {

  @Input() nome ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;

  constructor(
  ) {}

}
