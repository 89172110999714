import {CategoriesModel} from './categories.model';

export class RistoranteModel {
  link?: string;
  nome: string;
  ingredienti?: string[];
  coperti?: number;

  menu?: CategoriesModel[];
  opzioni?: any[];
}
