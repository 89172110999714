import {Inject, Injectable, Optional} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {CategoriesModel} from '../../models/menu/categories.model';

@Injectable({
  providedIn: 'any'
})
export class MenuService{
  private dbPath = '/';

  tutorialsRef: AngularFirestoreCollection<any[]>;

  constructor(private db: AngularFirestore,
              @Inject('ristorante') @Optional() private ristorante?: string,
              @Inject('raccolta') @Optional() private raccolta?: string,
  ) {
    console.log("ristoranteeee: ", raccolta);
    this.dbPath += raccolta;
    this.tutorialsRef = db.collection(this.dbPath);
  }

  getAll(): AngularFirestoreCollection<any> {
    return this.tutorialsRef;
  }

  getDoc( id: string ): AngularFirestoreDocument<any> {

    console.log("ciaociao getdoc: ", id);
    return this.tutorialsRef.doc(id);
  }

  getCollection( id: string ): AngularFirestoreDocument<any> {
    return this.tutorialsRef.doc(id);
  }

  create(tutorial: any, id: string): any {

    return this.tutorialsRef.doc(id).set( { ...tutorial });
    // return this.tutorialsRef.add({ ...tutorial });
  }

  update(id: string, data: any): Promise<void> {
    return this.tutorialsRef.doc(id).update(data);
    // return this.tutorialsRef.doc(id).update(data);
  }

  delete(id: string): Promise<void> {

    return this.tutorialsRef.doc(id).delete();
  }
}
