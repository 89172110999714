export class DishModel {
  link?: string;
  nome: string;
  ingredienti: string;
  prezzo: number;
  descrizione?: string;
  opzioni?: any[];
  quantita?: number;
  allergeni?: any[];
  id?: any;
}
