

<section style="background-color: white">

<div class="container2" style="transition: 1000ms ease; height: 60px; background-color: white; position: relative; position: -webkit-sticky; /* Safari */ position: sticky; top: 0px; z-index: 99;" >
  <div class="app" style="padding-bottom: 0px">
    <div (click)="clicco2()" style=" position: relative; margin-left: 25px; margin-top: 8px"><span class="backNero" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2F507257.png?alt=media&token=f4f84beb-eb17-450d-8d3e-4fa1c7b514dd'}" style=" padding: 0px;"></span></div>
    <h1 style="font-size: 18px; margin-top: 5%; margin-left: 6%; width: 100%; text-align: left; color: black; font-weight: bold;">Carrello</h1>
  </div>
</div>

<div>
  <div class="container2NotScrolled" id="lista-scontrino-dettaglio" style="pointer-events: auto; margin-top: 15px">
    <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let scontrino of temp">
      <paymentHeader class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [messaggio1]="scontrino.messaggio1" [messaggio2]="scontrino.messaggio2" [luogo]="scontrino.luogo" [link]="scontrino.link" [costo]="scontrino.prezzo" ></paymentHeader>
    </div>
  </div>
</div>


  <div class="container2NotScrolled" style="pointer-events: auto; margin-top: 15px">
    <div class="row" >
      <PaymentMetodCard class="col-12 col-md-12" style=" padding-left: 15px; margin-left: 0px; padding-right: 0px;" nome="Apple Pay" ></PaymentMetodCard>
    </div>
  </div>
  <div class="container2NotScrolled" style="pointer-events: auto; margin-top: 1px">
    <div class="row" >
      <PaymentMetodCard class="col-12 col-md-12" style=" padding-left: 15px; margin-left: 0px; padding-right: 0px;" nome="PayPal" ></PaymentMetodCard>
    </div>
  </div>
  <div class="container2NotScrolled" style="pointer-events: auto; margin-top: 1px">
    <div class="row" >
      <PaymentMetodCard class="col-12 col-md-12" style=" padding-left: 15px; margin-left: 0px; padding-right: 0px;" nome="**** 3812" ></PaymentMetodCard>
    </div>
  </div>

  <div id="store-locator" class="block" >
    <button style="width: 30px; height: 30px; border-radius: 30px;  border-width: 1px; border-color: black; background-color: #BDBDBE;"><p style="font-weight: lighter;  padding: 0px; font-size: 20px; ">+</p></button>

    <div class="container" style="text-align: left">
      <h4 style="margin-top: 10px; font-size: 16px; color: black;"><b>Aggiungi metodo di pagamento</b></h4>
    </div>
  </div>



</section>


