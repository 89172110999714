import {Component, HostListener, OnInit} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-dettaglio-compatto',
  templateUrl: './scontrino.html',
  styleUrls: ['./scontrino.css']
})
export class Scontrino implements OnInit {

  scontrini = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_c.png?alt=media&token=9493b38c-c854-47e7-aebf-5c3ba0ff7e56',
      nome: 'Coperto',
      luogo: '',
      prezzo: '3.00',
      quantita: '3'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      nome: 'Preferenze alimentari',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      nome: 'Crudo, Squacquerone e Rucola',
      luogo: 'No Stracchino',
      prezzo: '4.00',
      quantita: '1'
    }
  ];

  temp = [

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_c.png?alt=media&token=9493b38c-c854-47e7-aebf-5c3ba0ff7e56',
      messaggio1: 'Stato Ordine',
      messaggio2: 'Evaso',
      luogo: '',
      prezzo: '3.00',
      quantita: '3'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      messaggio1: 'Cameriere',
      messaggio2: 'Mike Bird',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      messaggio1: 'Lascia una recensione',
      messaggio2: '',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    }
  ];

  immagineProfilo = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/1e009f84-94db-4ff9-97fb-712b77ce7ee2.jpeg?alt=media&token=3c7b8239-ed07-49e6-aceb-9a06ba994e71';

  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale = null;

  percentuale2 = 10;



  constructor( private router: Router ) { }

  ngOnInit(): void {
  }


  clicco2(): void{
    console.log('porcodiooo');
    this.router.navigateByUrl('/scontrini');
  }

  buongiorno(event): void{
    event.stopPropagation();
    console.log('porcodiooo');
    this.router.navigateByUrl('/scontrini');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e): void {
    if (window.pageYOffset > 50) {

      // console.log("giù")

      const element = document.getElementById('lista-scontrino-scontrini');
      // console.log(element);

      if (element != null) {
        element.classList.add('container2Scrolled');
        element.classList.remove('container2NotScrolled');
      }

      // console.log(element)

      const element2 = document.getElementById('div-interno-scontrino');

      if (element2 != null) {
        element2.classList.add('div-interno-scrolled');
        element2.classList.remove('div-interno-non-scrolled');
      }

      const element3 = document.getElementById('h4-unoScontrino');

      if (element3 != null) {
        element3.classList.add('unoScontrinoScrolled');
        element3.classList.remove('unoScontrinoNotScrolled');
      }

      const element5 = document.getElementById('h4-dueScontrino');

      if (element5 != null) {
        element5.classList.add('dueScontrinoScrolled');
        element5.classList.remove('dueScontrinoNotScrolled');
      }

      const element4 = document.getElementById('immagine-scontrini-scontrino');

      if (element4 != null) {
        element4.classList.add('dettaglioScontrinoScrolled');
        element4.classList.remove('dettaglioScontrinoNonScrolled');
      }

      const element6 = document.getElementById('p-unoScontrino');

      if (element6 != null) {
        element6.classList.add('unoScontrinoScrolled');
        element6.classList.remove('unoScontrinoNotScrolled');
      }

      const element7 = document.getElementById('p-dueScontrino');

      if (element7 != null) {
        element7.classList.add('dueScontrinoScrolled');
        element7.classList.remove('dueScontrinoNotScrolled');
      }

      const element8 = document.getElementById('p-treScontrino');

      if (element8 != null) {
        element8.classList.add('treScontrinoScrolled');
        element8.classList.remove('treScontrinoNotScrolled');
      }




    } else {


      // console.log("su")

      const element = document.getElementById('lista-scontrino-scontrini');
      // console.log(element)
      if (element != null) {
        element.classList.remove('container2Scrolled');
        element.classList.add('container2NotScrolled');
      }
      // console.log(element)

      const element2 = document.getElementById('div-interno-scontrino');

      if (element2 != null) {
        element2.classList.remove('div-interno-scrolled');
        element2.classList.add('div-interno-non-scrolled');
      }

      const element3 = document.getElementById('h4-unoScontrino');

      if (element3 != null) {
        element3.classList.remove('unoScontrinoScrolled');
        element3.classList.add('unoScontrinoNotScrolled');
      }

      const element5 = document.getElementById('h4-dueScontrino');

      if (element5 != null) {
        element5.classList.remove('dueScontrinoScrolled');
        element5.classList.add('dueScontrinoNotScrolled');
      }

      const element4 = document.getElementById('immagine-scontrini-scontrino');

      if (element4 != null) {
        element4.classList.remove('dettaglioScontrinoScrolled');
        element4.classList.add('dettaglioScontrinoNonScrolled');
      }

      const element6 = document.getElementById('p-unoScontrino');

      if (element6 != null) {
        element6.classList.remove('unoScontrinoScrolled');
        element6.classList.add('unoScontrinoNotScrolled');
      }

      const element7 = document.getElementById('p-dueScontrino');

      if (element7 != null) {
        element7.classList.remove('dueScontrinoScrolled');
        element7.classList.add('dueScontrinoNotScrolled');
      }

      const element8 = document.getElementById('p-treScontrino');

      if (element8 != null) {
        element8.classList.remove('treScontrinoScrolled');
        element8.classList.add('treScontrinoNotScrolled');
      }

    }
  }


}
