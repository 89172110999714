// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'http://localhost:3000',
  api: {
    baseUrl: 'http://localhost:9000'
  }
};


export const environmentFirebase = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBYzhvaoK3MPFvrKSkEqTe8HTiLwcd-Pzo',
    authDomain: 'deweats.firebaseapp.com',
    databaseURL: 'https://deweats-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'deweats',
    storageBucket: 'deweats.appspot.com',
    messagingSenderId: '157089671742',
    appId: '1:157089671742:web:b1865a8f1bd3ec39e03f6f',
    measurementId: 'G-W4N9GL6T8C'
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
