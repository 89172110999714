import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'categorieCard',
  styles: [`


    .card-account {
      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 1px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 120px;
      margin: 10px;
    }

    /* On mouse-over, add a deeper shadow */
    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    /* Add some padding inside the card container */




    h4{
      margin-bottom: 0px;
    }




    #container {
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }

    .block {
      width: 100%;
      height: 80px;
      margin: 0px;
      margin-bottom: 10px;
      display: inline-flex;
      background: white;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: black;

      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);


    }

    #store-locator {
      color: black;
    }

    .store {
      position: relative;
      right: -15px;
      background-size: 10% auto;
      height: 40px;
      width: 40px;
      display: inline-block;
      background-color: white;
    }



  `],
  template: `

    <div id="store-locator" class="block">
      <img class="store" [ngStyle]="" style="background-size: 10% auto;" src="{{this.link}}" >
      <div style="padding-left: 45px">{{this.nome}}</div>
    </div>
  `
})
export class CategorieCard {

  @Input() nome ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('ciaociaociaociao', this.nome);
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
