import {ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {CdkDrag, CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ActivatedRoute, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {dettaglioMenuSelezioneService} from './service/dettaglio-menu-selezione-service';
import {FirestoreService} from '../../shared/services/firestore/firestore.service';

import {firebase} from '@firebase/app';
import '@firebase/auth';
import {Location} from '@angular/common';
import {MenuService} from '../../shared/services/firestore/menu.service';
import {UserDataService} from '../../shared/services/firestore/userData.service';
import {User} from '../../shared/services/user';

@Component({
  selector: 'app-dettaglio-compatto',
  templateUrl: './dettaglio-menu.html',
  styleUrls: ['./dettaglio-menu.css'],
  providers: [
    MenuService,
    {
      provide: 'raccolta',
      useValue: 'ristoranti',
    },
    UserDataService
  ],

})
export class DettaglioMenu implements OnInit {

  carrello = new Array();


  scontrini = [


    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_c.png?alt=media&token=9493b38c-c854-47e7-aebf-5c3ba0ff7e56',
      nome: 'Coperto',
      luogo: '',
      prezzo: '3.00',
      quantita: '3'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      nome: 'Preferenze alimentari',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      nome: 'Crudo, Squacquerone e Rucola',
      luogo: 'No Stracchino',
      prezzo: '4.00',
      quantita: '1'
    }, {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_c.png?alt=media&token=9493b38c-c854-47e7-aebf-5c3ba0ff7e56',
      nome: 'Coperto',
      luogo: '',
      prezzo: '3.00',
      quantita: '3'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      nome: 'Preferenze alimentari',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      nome: 'Crudo, Squacquerone e Rucola',
      luogo: 'No Stracchino',
      prezzo: '4.00',
      quantita: '1'
    }
  ];

  temp = [

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_c.png?alt=media&token=9493b38c-c854-47e7-aebf-5c3ba0ff7e56',
      nome: 'Coperto',
      luogo: '',
      prezzo: '3.00',
      quantita: '3'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2FRectangle_2_db.png?alt=media&token=b91c993c-cec8-4fd6-bf64-93634a312b24',
      nome: 'Preferenze alimentari',
      luogo: 'Taglieri Pizza',
      prezzo: '24.00',
      quantita: '2'
    }
  ];


  user: User;
  userDaSalvare: User;

  piatto;
  ristoranteDatabase;
  piattoDaSalvare;

  arrayOpzioni = new Array();

  piattoDatabase;

  carrelloTmp;

  immagineProfilo = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/1e009f84-94db-4ff9-97fb-712b77ce7ee2.jpeg?alt=media&token=3c7b8239-ed07-49e6-aceb-9a06ba994e71';

  nome = 'Totale';

  nome2 = 'Mancia per Mike';


  data = '23-01-2021 19.30';

  luogo = 'Rimini ,';

  prezzo = '26.00';

  prezzo2 = '2.60';

  percentuale2 = 10;

  selezionePrimaria = false;

  isOver = false;

  tot = 0;

  ristorante = 'ciao';

  numeroTavolo = 0;

  idPiatto = 'Crudo';


  constructor( private router: Router,
               private DettaglioMenuSelezioneService: dettaglioMenuSelezioneService,
               private changeDetection: ChangeDetectorRef,
               private menuService: MenuService,
               private userDataService: UserDataService,
               private location: Location,
               private route: ActivatedRoute
  ) {

    //this.DettaglioMenuSelezioneService.updateResultList(this.selezionePrimaria);

    this.route.params.subscribe(params => {
        console.log(params);
        if (params.ristorante) {
          this.ristorante = params.ristorante;
        }
        if (params.tavolo) {
          this.numeroTavolo = params.tavolo;
        }
        if (params.piatto) {
          if (params.piatto === 'modifica')
          {
            // tslint:disable-next-line:max-line-length
            this.router.navigate([this.ristorante + '/menuCard/modifica/modifica' ], { skipLocationChange: true} ); // , { skipLocationChange: true});
          }
          this.idPiatto = params.piatto;
        }
      }
    );

  }

  retriveInfo(): void {

    console.log(firebase.auth().currentUser);
    console.log(firebase.auth().currentUser.uid);

    this.userDataService.getDoc(firebase.auth().currentUser.uid).get().subscribe(
      data => {
        if (data.data() !== undefined) {

          console.log('menuCard: ', this.carrello);
          console.log('ho trovato: ', data.data());

          this.user = data.data();

          this.carrelloTmp = data.data().carrello;

          console.log('dopo carrello', this.carrelloTmp);

          /*
          let i = 0;
          for (const x in data.data()) {
            this.carrello.push(data.data()[i]);
            i = i + 1;
          }
          // @ts-ignore
          // this.menuCard = CategoriesModel[data.data];
          // @ts-ignore
          this.menuCard.concat(data.data);
          // this.menuCard = data.data();

           */
          this.menuService.getDoc(this.ristorante).get().subscribe(
            dataMenu => {

              console.log("dentro a menuService 1");


              if (dataMenu.data() !== undefined) {

                console.log('ho trovato il ristorante: ', dataMenu.data());

                this.ristoranteDatabase = dataMenu.data();

                dataMenu.data().menu.forEach((categoria, indexUser) => {

                  categoria.menu.every((piatto, indexPiatto) => {

                    if (this.idPiatto.toString() === piatto.id.toString()) {

                      console.log("dentro a menuService");

                      this.piatto = piatto;



                      this.piattoDaSalvare = piatto;


                      this.piattoDaSalvare.quantita = 1;
                      console.log("piatto da salvare:", piatto);


                      this.prezzo = piatto.prezzo;

                      this.piatto.opzioni.forEach((opzione, indexOpzione) => {

                        console.log("11111111");

                        opzione = {
                          ingrediente: opzione.ingrediente,
                          prezzo: opzione.prezzo,
                          allergeni: opzione.allergeni,
                          selezionato: true

                        };


                      });


                      return false;
                    }
                    return true;

                  });
                });


                this.piattoDatabase = {
                  descrizione: this.piatto.descrizione,
                  ingredienti: this.piatto.ingredienti,

                  nome: this.piatto.nome,

                  opzioni: this.piatto.opzioni,
                  prezzo: this.piatto.prezzo,
                  id: this.piatto.id,
                };


                console.log('dopo carrello', this.carrelloTmp);
                console.log('ristorante', this.ristoranteDatabase);
                console.log('utente', this.user);
                console.log('piatto', this.piatto);


                /*
                let i = 0;
                for (const x in data.data()) {
                  this.carrello.push(data.data()[i]);
                  i = i + 1;
                }
                // @ts-ignore
                // this.menuCard = CategoriesModel[data.data];
                // @ts-ignore
                this.menuCard.concat(data.data);
                // this.menuCard = data.data();

                 */


              }
            }
          );

        }
      }
    );

  }

  salva(): void {
    console.log('Salva carrello: ', this.carrelloTmp);

    console.log("piatto da salvare: ", this.piattoDaSalvare);




    const i = this.carrelloTmp.piatti.findIndex(x =>
      x.id === this.piattoDaSalvare.id
      && Boolean(x.opzioni.find(y => this.arrayOpzioni.includes(y.ingrediente)))
      || x.id === this.piattoDaSalvare.id
      && Boolean(x.opzioni.length < 1)

    );



    console.log('Salva carrello dopo ricerca: ', this.carrelloTmp);


    if (i > -1)
    {
      console.log("piatto uguale 111111", this.carrelloTmp.piatti[i] );
      this.carrelloTmp.piatti[i].quantita ++;
      console.log("piatto uguale 22222222", this.carrelloTmp.piatti[i] );


    }
    else {
      this.carrelloTmp.piatti.push(this.piattoDaSalvare);
    }



    console.log("carrello tmp: ", this.carrelloTmp);



    if (this.selezionePrimaria === false) {

      this.piattoDaSalvare.opzioni = new Array();

    }



    console.log("NuovaCarta da sakvare: ", this.carrelloTmp);

    console.log("user: ", this.user);

    this.user.carrello = this.carrelloTmp;

    console.log("user da salvare: ", this.user);

    this.userDataService.delete(this.user.uid);

    // this.menuService.create(this.menuCard, this.ristorante);

    console.log('user da salvare: ', this.user);

    const userDasalvareSalva = {

      uid: this.user.uid,
      email: this.user.email,
      displayName: this.user.displayName,
      photoURL: this.user.photoURL,
      emailVerified: this.user.emailVerified,
      allergeni: this.user.allergeni,
      scontrini: this.user.scontrini,
      carrello: this.carrelloTmp,

    };

    console.log('user da salvare salva: ', userDasalvareSalva);



    this.userDataService.create(userDasalvareSalva, this.user.uid);


    this.router.navigate([this.ristorante + '/menu']); // , { skipLocationChange: true});



    // this.router.navigate(['/menuCard']);
  }

  ngOnInit(): void {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        this.retriveInfo();
      } else {
        // No user is signed in.
      }
    });


  }



  clicco2(): void{
    console.log('porcodiooo');
    this.router.navigateByUrl('/scontrini');
  }

  buongiorno(event): void{
    event.stopPropagation();
    console.log('porcodiooo');
    // this.router.navigateByUrl('/menuCard');
    this.location.back();
  }


  selezionePrimariaMetodo(newValue, opzione): void {

    this.selezionePrimaria = true;

    event.stopPropagation();

    console.log("piatto menu? ", this.piattoDatabase);

    console.log(newValue);
    console.log("opzione selezionata? ", opzione);

    console.log("piatto menu? ", this.piattoDatabase);


    this.piattoDatabase.opzioni.forEach((value, index) => {

      console.log('dentro ad opzioni del piatto: ', value);

      if (index === newValue) {
        value.selezionato = true;
      } else {
        value.selezionato = false;
      }
    });

    console.log("piatto menu 2? ", this.piatto);

    console.log("piatto modificato selezione: ", this.piatto);

    console.log("piatto da salvare: ", this.piattoDaSalvare);

    console.log("piatto menu: ", this.piattoDatabase);

    const opzioneDaSalvare = new Array();

    opzioneDaSalvare.push(
      {

        allergeni: opzione.allergeni,
        ingrediente: opzione.ingrediente,
        prezzo: opzione.prezzo,
      }
    );

    this.arrayOpzioni.push(opzione.ingrediente);

    this.piattoDaSalvare.opzioni = opzioneDaSalvare;


    console.log("piatto da salvare modificato: ", this.piattoDaSalvare);

    this.prezzo = this.piattoDaSalvare.prezzo;

    this.piattoDaSalvare.opzioni.forEach((value, index) => {

        this.prezzo = this.prezzo + value.prezzo;

    });

    console.log("carrelloTmpDopoSelezione: ", this.carrelloTmp);

    //this.selezionePrimaria = newValue;  // don't forget to update the model here
    //this.DettaglioMenuSelezioneService.updateResultList(this.selezionePrimaria);
    this.changeDetection.detectChanges();


    // ... do other stuff here ...
  }

  clickMenu(event): void{
    //console.log('ciaobiuyew giuye231<giue23<y789e23<');
    this.salva();
    event.stopPropagation();
  }

}
