import {Component} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'notify-snack-bar',
  templateUrl: 'notifySnackBar.html',
  styles: [`
    .example-pizza-party {
      color: hotpink;
    }
  `],
})
export class notifySnackBar {}
