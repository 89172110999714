import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.html',
  styleUrls: ['./sign-in.css']
})

export class SignIn implements OnInit {

  constructor(
    public authService: AuthService
  ) { }

  ngOnInit() { }

}
