<profileCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; z-index: 0;" nome="Scontrini" colore="white" titolo="black" height="8vh"></profileCard>


<div class="container2">

  <div class="container" style="padding-top: 0px">
  <div class="row" style="padding-top: 0%" *ngFor="let scontrino of receipts">
    <!--receipt class="col col-md" [nome]="scontrino.nome" [luogo]="scontrino.luogo" [link]="scontrino.link" [costo]="scontrino.prezzo" (click)=" this.router.navigate(['scontrini/dettaglioScontrino']); "></receipt-->

    <receipt class="col col-md" [nome]="scontrino.ristorante" [luogo]="scontrino.provincia" [link]="scontrino.link" [costo]="scontrino.costo" (click)=" this.router.navigate(['scontrini/dettaglioScontrino']); "></receipt>

  </div>
</div>

</div>
