import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'profileCard',
  styles: [`


    .card-account {
      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 1px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 120px;
      margin: 10px;
    }

    /* On mouse-over, add a deeper shadow */
    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    /* Add some padding inside the card container */



    /* Demo styles */

    ul {
      list-style: none;
      padding: 0;
    }

    b{
      text-align: right;
      font-size: 15px;
      vertical-align: 90%;
      margin-bottom: 0px;
      margin-top: 0;
    }

    p{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    p.due{
      font-size: 15px;
      vertical-align: 0px;
      padding-bottom: 0px;
      font-weight: lighter;
    }

    p.tre{
      color: #E95237;
      font-size: 12px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-weight: lighter;
    }

    .containerScontrino {
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 5px;
      max-height: 50px;
      border-radius: 0px;
      horiz-align: left;
      float: left;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;

    }

    h4{
      margin-bottom: 0px;
    }

    .header {
      display: inline-block;
      width: 100%;
      background: white;
    }
    .playerOne {
      float: right;
    }
    .playerTwo {
      float: left;
    }


    #container {
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }

    .block {
      width: 100%;
      min-height: 15vh;
      margin: 0px;
      display: inline-flex;
      background: #F94F4F;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: white;



    }

    .blocco {

      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;


    }


    #store-locator {
      color: grey;
    }

    .store {
      background: url(https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fbento-box_1f371.png?alt=media&token=ac831a36-112d-4f49-a115-c7616e3e1251) no-repeat center left;
      position: relative;
      right: -15px;
      background-size: 50% auto;
      height: 65px;
      width: 65px;
      display: inline-block;
      border-radius: 50%;
      border: 1px solid grey;
    }

  `],
  template: `


    <div id=" containerScontrino" class="block" style="background: {{this.colore}}; min-height: {{this.height}}">
        <div style="width: 100%;">
          <h4 style="padding-left: 5%; font-size: 20px; font-weight: bold" *ngIf="!titolo"> Ciao {{this.nome}}! </h4>
          <h4 style="padding-left: 5%; font-size: 20px; font-weight: bold; color: {{this.titolo}}" *ngIf="titolo"> {{this.nome}}</h4>
        </div>
      <!--div style="padding-left: 35px">{{this.nome}}</div>
      <div style="padding-left: 35px">{{this.costo}}</div-->
    </div>

  `
})
export class ProfileCardComponent {

  @Input() nome ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;
  @Input() colore ;
  @Input() titolo;
  @Input() height;



  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('ciaociaociaociao', this.nome);
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
