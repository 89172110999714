


<div class="container2" style="background: white">
  <div class="app" style="padding-bottom: 0px">
    <div (click)="clicco2()" style=" position: relative; margin-left: 25px; margin-top: 8px"><span class="store1" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2F507257.png?alt=media&token=f4f84beb-eb17-450d-8d3e-4fa1c7b514dd'}" style=" padding: 0px;"></span></div>
    <h1 style="font-size: 24px; margin-top: 14px; margin-left: 25px">Categorie</h1>
  </div>
</div>




<div class="container" style="padding-top: 15px">
  <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let scontrino of scontrini">
    <categorieCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="scontrino.nome" [luogo]="scontrino.luogo" [link]="scontrino.link" [costo]="scontrino.prezzo" ></categorieCard>
  </div>
</div>
