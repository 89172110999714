import { BehaviorSubject, Observable } from 'rxjs';
import {EventEmitter, Injectable} from '@angular/core';
import {PiattoModel} from '../../../shared/models/piatto.model';



@Injectable({ providedIn: 'root' })
export class ModificaPiattoService {
  private resultList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public resultList$: Observable<boolean> = this.resultList.asObservable();
  public piatto: any;
  public indexPiatto: any;
  public indexCategoria: any;


  emitter = new EventEmitter();


  updateResultList(updatedList): void {
    this.resultList.next(updatedList);
  }


  updatePiatto(piatto, indexPiatto, indexCategoria): void {
    const risultato = {

      'piatto': piatto,
      'indexPiatto': indexPiatto,
      'indexCategoria': indexCategoria,

    };
    this.emitter.emit(risultato);

  }


  updatePiattoRotto(piatto, indexPiatto, indexCategoria, prezzo): void {
    const risultato = {

      'piatto': piatto,
      'indexPiatto': indexPiatto,
      'indexCategoria': indexCategoria,

    };
    risultato.piatto.prezzo = prezzo;
    this.emitter.emit(risultato);

  }

}
