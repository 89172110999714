import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { OnChanges, SimpleChanges } from '@angular/core';

import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 *
 *
 * card dettaglio del piatto nel menu
 *
 *
 */
@Component({
  selector: 'menuDetailCard',
  styles: [`

    .card-account {
      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 1px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 120px;
      margin: 10px;
    }

    /* On mouse-over, add a deeper shadow */
    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .background-allergia{
      background-color: rgba(252,232,232,0.6);
    }

    .background-stadard{
      background-color: rgb(251,251,251);
    }



    /* Add some padding inside the card container */



    /* Demo styles */

    ul {
      list-style: none;
      padding: 0;
    }

    b{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    p{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    p.due{
      font-size: 15px;
      vertical-align: 0px;
      padding-bottom: 0px;
      font-weight: lighter;
    }

    p.tre{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-weight: lighter;
    }

    .containerScontrino {
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 5px;
      max-height: 50px;
      border-radius: 0px;
      horiz-align: left;
      float: left;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;

    }

    h4{
      margin-bottom: 0px;
    }

    .header {
      display: inline-block;
      width: 100%;
      background: white;
    }
    .playerOne {
      float: right;
    }
    .playerTwo {
      float: left;
    }




    #container {
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }

    .block {
      width: 100%;
      height: 100px;
      margin: 0px;
      margin-bottom: 1px;
      display: inline-flex;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: black;

      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);


    }

    #store-locator {
      color: black;
    }


  `],
  template: `

    <div [ngClass]="{'background-allergia': allergia == true && carrello == false, 'background-standard': allergia == false || carrello == true }" id="store-locator " class="block" (click)="clicco()">
      <div class="container" style="text-align: left;">
        <h4 style="font-weight: lighter; margin-top: -20px; margin-left: 0px;"><p style="color: black; font-weight: bold; font-size: 16px">{{this.quantita}}x</p></h4>
        <h4 style="font-weight: lighter; margin-top: -10px; margin-left: 20%; color: black; font-size: 10px;"><p style="font-size: 17px">{{this.nome}}</p></h4>
        <h4 *ngIf="!allergia" style="font-weight: lighter; margin-top: 0px; margin-left: 20%; color: grey; font-size: 10px;"><p style="font-size: 16px">{{this.ingredienti}}</p></h4>
        <h4 [ngStyle]="allergia? {'color': 'red'} : {'color': 'black'}" *ngIf="allergia" style="font-weight: lighter; margin-top: 0px; margin-left: 20%; color: grey; font-size: 10px;"><p style="font-size: 16px">allergeni: {{this.allergeni}}</p></h4>
        <!--button mat-fab
                style="background-color:rgba(0, 0, 0, 0);; margin-top: -30px; width: 30px; height: 30px; border-radius: 35px; display: flex; align-items: center; justify-content: center; border-width: 3px; border-color: black">
          <span style="font-size: 15px; color: black">-</span>
        </button-->
      </div>
      <p style="margin-top: -60px; text-align: right; width:20%;  display: inline-block; border: none; color: black; font-size: 16px; margin-right: 20px; font-weight: lighter">{{this.prezzo}} €</p>

    </div>
  `
})
export class MenuDetailCardComponent implements OnChanges {

  @Input() nome;
  @Input() ingredienti;
  @Input() link;
  @Input() prezzo;
  @Input() quantita;
  @Input() allergene;
  @Input() piatto: any;
  @Input() user: any;

  @Input() carrello: any;


  allergia = false;
  allergeni = '';


  constructor(
    // public authService: AuthService,
    private router: Router
  ) {

    console.log('user dentro a menucard: ', this.user);
    console.log('piatto dentro a menucard: ', this.piatto);


  }


  clicco(): void {
    console.log('ciaociaociaociao', this.nome);
  }

  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void {
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.allergia = false;
    this.allergeni = '';

    console.log('user dentro a menucard: ', this.user);
    console.log('piatto dentro a menucard: ', this.piatto);

    if (this.piatto.ingredienti) {
      this.piatto.ingredienti.forEach(
        ingrediente => {

          ingrediente.allergeni.forEach(
            allergeneIngrediente => {

              this.user.allergeni.forEach(
                allergeneUser => {

                  console.log('allergene piatto: ', allergeneUser);
                  console.log('allergene user: ', allergeneIngrediente);

                  if (allergeneIngrediente === allergeneUser) {
                    this.allergia = true;
                    this.allergeni += ' ' + allergeneIngrediente;
                  }
                }
              );


            }
          );
        }
      );
    }

    if (this.piatto.opzioni) {
      this.piatto.opzioni.forEach(
        opzione => {

          opzione.allergeni.forEach(
            allergeneOpzione => {

              this.user.allergeni.forEach(
                allergeneUser => {

                  console.log('allergene opzione: ', allergeneOpzione);
                  console.log('allergene user: ', allergeneUser);
                  if (allergeneUser === allergeneOpzione) {
                    this.allergia = true;
                    this.allergeni += ' ' + allergeneOpzione;
                  }
                }
              );

            }
          );
        }
      );
      // You can also use categoryId.previousValue and
      // categoryId.firstChange for comparing old and new values

    }
  }
}
