
<section class="web_homePage" (scroll)="onWindowScroll($event);">
  <!-- rest of the html code goes here -->

<div class="containerDettaglioScontrino">

<div class="container2" style="">
  <div class="app" style="padding-bottom: 0px">
    <div (click)="buongiorno($event)" style=" position: fixed; margin-left: 25px; margin-top: 8px; z-index: 99; pointer-events: auto;"><span class="store1" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2F507257.png?alt=media&token=f4f84beb-eb17-450d-8d3e-4fa1c7b514dd'}" style=" padding: 0px;"></span></div>
    <h1 style="font-size: 24px; margin-top: 14px; margin-left: 25px">Scontrini</h1>
  </div>
</div>

<receiptHeaderCompact class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="this.nome" [luogo]="this.luogo" [link]="this.immagineProfilo" [costo]="this.prezzo" [data]="this.data" ></receiptHeaderCompact>

</div>


<div>
  <p style="text-align: left; width:80%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; color: grey; margin-top: 250px; margin-bottom: 0px; margin-left: 20px; font-weight: normal">Dettagli Recensione</p>
  <div class="container2NotScrolled" id="lista-scontrino-dettaglio" style="pointer-events: auto; margin-top: 15px">
    <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let scontrino of temp">
      <receiptOrderCard1 class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [messaggio1]="scontrino.messaggio1" [messaggio2]="scontrino.messaggio2" [luogo]="scontrino.luogo" [link]="scontrino.link" [costo]="scontrino.prezzo" ></receiptOrderCard1>
    </div>
  </div>
</div>


  <div>
    <p style="text-align: left; width:80%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; color: grey; margin-top: 20px; margin-bottom: 0px; margin-left: 20px; font-weight: normal">Dettagli Ordinazione</p>
    <div class="container2NotScrolled" id="lista-scontrino-dettaglio" style="pointer-events: auto; margin-top: 15px">
      <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let scontrino of scontrini">
        <receiptOrderCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="scontrino.nome" [luogo]="scontrino.luogo" [link]="scontrino.link" [costo]="scontrino.prezzo" [quantita]="scontrino.quantita" ></receiptOrderCard>
      </div>
    </div>
  </div>

  <div class="container2NotScrolled" id="lista-scontrino-dettaglio" style="pointer-events: auto; margin-top: 15px">
    <div class="row" style="padding-top: 0%; padding-left: 0px">
      <dettaglioScontrinoFinaleCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="this.nome" [luogo]="this.luogo"  [costo]="this.prezzo" ></dettaglioScontrinoFinaleCard>
    </div>
  </div>

  <div class="container2NotScrolled" id="lista-scontrino-dettaglio" style="pointer-events: auto; margin-top: 1px">
    <div class="row" style="padding-top: 0%; padding-left: 0px">
      <dettaglioScontrinoFinaleCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="this.nome2" [luogo]="this.luogo" [costo]="this.prezzo2" [percentuale]="this.percentuale2" ></dettaglioScontrinoFinaleCard>
    </div>
  </div>


</section>

