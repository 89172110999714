import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {MenuService} from "../../shared/services/firestore/menu.service";
import {firebase} from "@firebase/app";

@Component({
  selector: 'app-dettaglio',
  templateUrl: './scontrini.html',
  styleUrls: ['./scontrini.css'],
  providers: [
    MenuService,
    {
      provide: 'ristorante',
      useValue: 'menu',
    },
    {
      provide: 'raccolta',
      useValue: 'users',
    },
  ],
})
export class Scontrini implements OnInit {


  currentItem = 'John Doe';
  currentDescription = 'Architect & Engineer';

  currentLink = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Flobster_1f99e.png?alt=media&token=c3fbe73b-01ec-415b-8a38-fedb138ec75a';


  links = ['https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fbento-box_1f371.png?alt=media&token=ac831a36-112d-4f49-a115-c7616e3e1251',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fburrito_1f32f.png?alt=media&token=83ff348b-69af-4c5d-9145-0fd9b803cdee',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fcocktail-glass_1f378.png?alt=media&token=f7138189-7c53-4b18-9e76-16647f688e0f',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fcut-of-meat_1f969.png?alt=media&token=471d25c7-83c8-4610-8be2-85a81896aeb2',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fdoughnut_1f369.png?alt=media&token=2e38d160-851a-46fb-8437-c8022ba8bb7c',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Ffrench-fries_1f35f.png?alt=media&token=1fee5b03-a9f5-4c7c-b189-0375b6261aa4',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fgreen-salad_1f957.png?alt=media&token=42309249-7634-4032-9c07-9f812d4816ed',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhamburger_1f354.png?alt=media&token=8579616f-7a0b-4a25-a9ce-6ca5e013f2d0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhot-beverage_2615.png?alt=media&token=15a02cc7-b508-48c0-8dba-3d1bf6d1be99',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fhot-dog_1f32d.png?alt=media&token=dc13d99d-d840-4e63-89d0-9e95d9a047c0',
    'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Flobster_1f99e.png?alt=media&token=c3fbe73b-01ec-415b-8a38-fedb138ec75a'];



  scontrini = [

    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/d5f6b64a-81c8-41e1-a36a-018476345782.jpeg?alt=media&token=2b40ef69-6d9e-4634-925b-6e5c98cc08a0',
      nome: 'Fuego-bbq',
      luogo: 'Riggione, 29 gennaio',
      prezzo: '147.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/1e009f84-94db-4ff9-97fb-712b77ce7ee2.jpeg?alt=media&token=3c7b8239-ed07-49e6-aceb-9a06ba994e71',
      nome: 'SP.accio',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f',
      nome: 'Da Rinaldi',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/d5f6b64a-81c8-41e1-a36a-018476345782.jpeg?alt=media&token=2b40ef69-6d9e-4634-925b-6e5c98cc08a0',
      nome: 'Fuego-bbq',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/1e009f84-94db-4ff9-97fb-712b77ce7ee2.jpeg?alt=media&token=3c7b8239-ed07-49e6-aceb-9a06ba994e71',
      nome: 'SP.accio',
      luogo: 'Rimini, 10 gennaio',
      prezzo: '35.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/d5f6b64a-81c8-41e1-a36a-018476345782.jpeg?alt=media&token=2b40ef69-6d9e-4634-925b-6e5c98cc08a0',
      nome: 'Fuego-bbq',
      luogo: 'Riggione, 8 gennaio',
      prezzo: '93.60 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/d5f6b64a-81c8-41e1-a36a-018476345782.jpeg?alt=media&token=2b40ef69-6d9e-4634-925b-6e5c98cc08a0',
      nome: 'Fuego-bbq',
      luogo: 'Riggione, 6 gennaio',
      prezzo: '72.50 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/1e009f84-94db-4ff9-97fb-712b77ce7ee2.jpeg?alt=media&token=3c7b8239-ed07-49e6-aceb-9a06ba994e71',
      nome: 'SP.accio',
      luogo: 'Rimini, 23 gennaio',
      prezzo: '34.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/31917dfc-1931-408b-a34b-42764a9bcfa2.jpeg?alt=media&token=77eb3bca-c40f-4e05-9830-5b9d7832a53f',
      nome: 'Da Rinaldi',
      luogo: 'Rimini, 22 gennaio',
      prezzo: '56.00 €'
    },
    {
      link: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/d5f6b64a-81c8-41e1-a36a-018476345782.jpeg?alt=media&token=2b40ef69-6d9e-4634-925b-6e5c98cc08a0',
      nome: 'Fuego-bbq',
      luogo: 'Riggione, 18 gennaio',
      prezzo: '72.50 €'
    },

  ];
  receipts;

  constructor(
    private http: HttpClient,
    private router: Router,
    private menuService: MenuService){
  }

  previousWeekData(): void {
    console.log('porcodio');
    this.router.navigate(['/users']);
    console.log('porcodio2');

  }

  ngOnInit(): void {


    firebase.auth().onAuthStateChanged((user) => {
      console.log("ciaociaociao");
      if (user) {
        // User is signed in.
        this.retriveScontrini(user.uid);
      } else {
        // No user is signed in.
      }
    });


  }

  retriveScontrini(user: any): void {

    this.menuService.getDoc(user).get().subscribe(
      data => {
        console.log("ciaociao 2")
        if (data.data() !== undefined)
        {

          console.log('ho trovato: ', data.data());
          this.receipts = data.data().scontrini;

          // this.menuCard = data.data();
        }
      }
    );

  }

}
