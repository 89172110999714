import { BehaviorSubject, Observable } from 'rxjs';
import {Injectable} from '@angular/core';


@Injectable({ providedIn: 'root' })
export class removeNavBarElementService {
  private resultList: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public resultList$: Observable<boolean> = this.resultList.asObservable();

  updateResultList(updatedList): void {
    this.resultList.next(updatedList);
  }
}
