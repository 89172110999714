
<!--button mat-button Style="background-color: cornflowerblue;" (click)="disattiva == true ? disattiva = false : disattiva = true">Modifica L'ordine della lista</button>
<div Style="padding-bottom: 20px"></div>
<div
  cdkDropList
  class="example-list"
  (cdkDropListDropped)="drop($event)"
  [cdkDropListSortPredicate]="sortPredicate"
  [cdkDropListDisabled]="disattiva"
  >
  <div
    class="example-box"
    *ngFor="let number of numbers"
    [cdkDragData]="number"
    cdkDrag
  >{{number}}
  </div>
</div-->



<profileCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; z-index: 0" [nome]="this.nome" [luogo]="this.luogo" [link]="this.immagineProfilo" [costo]="this.prezzo" ></profileCard>


<div class="container" style=" height: 100vh; background-color: white; border-top-left-radius: 20px; border-top-right-radius: 20px; padding-top: 20px; z-index: 1; position: absolute; margin-top: -25px">
  <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let scontrino of scontrini" (click)="cliccoAccount(scontrino)">
    <accountCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="scontrino.nome" [luogo]="scontrino.luogo" [link]="scontrino.link" [costo]="scontrino.prezzo" ></accountCard>
  </div>

  <div class="row" style="padding-top: 0%; padding-left: 0px" >
    <accountCard *ngIf="authService.isLoggedIn == true" (click)="authService.SignOut()" class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px; " nome="Esci" luogo="Esci" link="{{logOutLink}}" costo="esci" ></accountCard>

  </div>
  </div>
