import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'receiptOrderCard1',
  styles: [`


    .card-account {
      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 1px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);

      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 120px;
      margin: 10px;
    }

    /* On mouse-over, add a deeper shadow */
    .card-scontrino:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    /* Add some padding inside the card container */



    /* Demo styles */

    ul {
      list-style: none;
      padding: 0;
    }

    b{
      text-align: right;
      font-size: 15px;
      vertical-align: 90%;
      margin-bottom: 0px;
      margin-top: 0;
    }

    p{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    p.due{
      font-size: 15px;
      vertical-align: 0px;
      padding-bottom: 0px;
      font-weight: lighter;
    }

    p.tre{
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-weight: lighter;
    }

    .containerScontrino {
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 5px;
      max-height: 50px;
      border-radius: 0px;
      horiz-align: left;
      float: left;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;

    }

    h4{
      margin-bottom: 0px;
    }

    .header {
      display: inline-block;
      width: 100%;
      background: white;
    }
    .playerOne {
      float: right;
    }
    .playerTwo {
      float: left;
    }




    #container {
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }

    .block {
      width: 100%;
      height: 80px;
      margin: 0px;
      margin-bottom: 1px;
      display: inline-flex;
      background: white;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: black;

      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);


    }

    #store-locator {
      color: black;
    }



  `],
  template: `

    <div id="store-locator" class="block" (click)="clicco()">
      <div class="container" style="text-align: left">
        <h4 style="margin-top: 10px"><b>{{this.messaggio1}}</b></h4>
        <h4 style="font-weight: lighter; margin-top: -10px"><p>{{this.messaggio2}}</p></h4>
      </div>
      <button style="width: 20%; border-radius: 15px; margin-right: 15px; border: 0px"><p style="font-weight: lighter; margin: 4px; padding: 0px; font-size: 15px">Dettagli</p></button>
    </div>
  `
})
export class cardDettaglioScontrino {

  @Input() messaggio1 ;
  @Input() messaggio2 ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('porcodio');
    if(this.messaggio1.includes('recensione')){
      this.router.navigateByUrl('/recensione/aa');
    }
  }
  /**
   * Simulate logout
   */


}
