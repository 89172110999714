
<section class="web_homePage" (scroll)="onWindowScroll($event);">
  <!-- rest of the html code goes here -->

<div class="containerDettaglioScontrino">

<div class="container2" style="">
  <div class="app" style="padding-bottom: 0px">
    <div (click)="buongiorno($event)" style=" position: fixed; margin-left: 25px; margin-top: 8px; z-index: 99; pointer-events: auto;"><span class="store1" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2F507257.png?alt=media&token=f4f84beb-eb17-450d-8d3e-4fa1c7b514dd'}" style=" padding: 0px;"></span></div>
    <h1 style="font-size: 24px; margin-top: 14px; margin-left: 25px">Recensione</h1>
  </div>
</div>
</div>
  <div class="container">
    <form (ngSubmit)="submitReview()" style="padding-top: 20%">
      <div class="form-group">
        <label for="title" style="font-size: 20px; font-weight: bold">Titolo:</label>
        <input type="text" class="form-control shadow" style="border-color: white; border-radius: 15px" id="title" [(ngModel)]="review.title" name="title" required>
      </div>
      <div class="form-group" >
        <label for="reviewText" style="font-size: 20px; font-weight: bold">Testo della Recensione:</label>
        <textarea class="form-control shadow" style="border-color: white; border-radius: 15px" id="reviewText" [(ngModel)]="review.text" name="reviewText" rows="5" required></textarea>
      </div>
      <div class="form-group">
        <label for="media" style="font-size: 20px; font-weight: bold">Importa Foto/Video:</label>
        <label class="btn btn-primary " for="media" style="padding-top: 15px;width: 100%; color: rgba(230,89,52,1); background-color: white; border-radius: 20px; border: 1px solid rgba(230,89,52,1); height: 70px; font-size: 20px; font-weight: bold">Carica File</label>
        <input type="file" id="media" style="background-color: white;" (change)="onFileSelected($event)"  accept="image/*, video/*" multiple>
        <div id="preview" style="border: 1px solid rgba(230,89,52,1); border-radius: 20px; margin-top: 15px" *ngIf="selectedFiles.length > 0">
          <img *ngFor="let file of selectedFiles" [src]="file.dataURL" style="max-width: 100px; max-height: 100px; margin-right: 5px;">
        </div>
      </div>
      <button type="submit" class="btn btn-primary" style="width: 100%; background-color: rgba(230,89,52,1); border-radius: 20px; border: 1px solid rgba(230,89,52,1); height: 70px; font-size: 20px; font-weight: bold">Invia Recensione</button>
    </form>
  </div>







</section>

