import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'accountCard',
  styles: [`



    /* Demo styles */

    ul {
      list-style: none;
      padding: 0;
    }

    b {
      text-align: right;
      font-size: 15px;
      vertical-align: 90%;
      margin-bottom: 0px;
      margin-top: 0;
    }

    p {
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }

    p.due {
      font-size: 15px;
      vertical-align: 0px;
      padding-bottom: 0px;
      font-weight: lighter;
    }

    p.tre {
      font-size: 15px;
      vertical-align: 100%;
      padding-bottom: 5px;
      margin-bottom: 0px;
      font-weight: lighter;
    }

    .containerScontrino {
      padding-bottom: 10px;
      padding-top: 15px;
      padding-left: 5px;
      max-height: 50px;
      border-radius: 0px;
      horiz-align: left;
      float: left;
    }

    .center-cropped {
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 400px;

    }

    h4 {
      margin-bottom: 0px;
    }


    .block {
      width: 100%;
      height: 60px;
      margin: 0px;
      display: inline-flex;
      background: white;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      color: #737373;

      border-radius: 0px;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;


    }



    .store {
      background: url(https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fbento-box_1f371.png?alt=media&token=ac831a36-112d-4f49-a115-c7616e3e1251) no-repeat center left;
      position: relative;
      right: -15px;
      background-size: 50% auto;
      height: 40px;
      width: 40px;
      display: inline-block;
    }



  `],
  template: `

    <div id="store-locator" class="block">
      <div style="float: left; width: 15%; padding-left: 5%">
        <img style="width: 70%" src="{{this.link}}">
      </div>
      <div style="float: left; width: 70%; padding-left: 15px" [style.color] = "costo == 'esci' ? '#F94F4F' : '#737373'">{{this.nome}} </div>
      <div style="float: left; width: 15%; padding-right: 5%; text-align: right" *ngIf="costo != 'esci'">
      <img style="width: 60%" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAuElEQVR4nO2XPQrCQBBGRwt/Gj2BpxG8zRZ5E1ixmRsIYqEQ76NHsosEDAQbK9mRzIPtH99+M8uKBMGPSSnNgRNwEJGJlKSu652qtu9zLSpkZgvg4UYo57x2J6Sq914IuJnZtKgQkdC/JYQnoZTSalhqVW1clRq4REJfEjpLSYCNqj57oa5T404mfzwVxaYqe5mm7DURLbWBs2ORJq6GSGRAVVVLF2XtALYuRDrMbAYcgX3xH2Uwel4TMhC97NRWyAAAAABJRU5ErkJggg==">
      </div>
    </div>
  `
})
export class AccountCardComponent {

  @Input() nome ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('porcodio');
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
