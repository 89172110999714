


<div class="container2" style="background: white">
  <div class="app" style="padding-bottom: 0px">
    <div (click)="clicco2()" style=" position: relative; margin-left: 25px; margin-top: 8px"><span class="store1" [ngStyle]="{background: 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2F507257.png?alt=media&token=f4f84beb-eb17-450d-8d3e-4fa1c7b514dd'}" style=" padding: 0px;"></span></div>
    <h1 style="font-size: 24px; margin-top: 14px; margin-left: 25px">Preferenze Alimentari</h1>
  </div>
</div>

<div class="container" style="padding-top: 20px;">
  <div class="row" style="margin: 0px 0px 0px">
    <p style="text-align: left; width:80%; display: inline-block; background-color: #fbfbfb; border: none; font-weight: bold; font-size: 16px; padding-left: 0px; margin: 0px; white-space: nowrap; text-overflow: ellipsis; padding-bottom: 15px">Indica le tue allergie!</p>
  </div>
</div>

<div class="sb-example-1 shadow" style="">
  <div class="search">
    <button type="submit" class="searchButton2">
      <i class="fa fa-search"></i>
    </button>
    <input type="text" class="searchTerm" placeholder="Cerca" [(ngModel)]="this.ricerca" (ngModelChange)="valuechange($event)">
    <button type="submit" class="searchButton">
    </button>
  </div>
</div>

<div>
  <div class="container2NotScrolled" id="lista-scontrino-dettaglio" style="pointer-events: auto; margin-top: 15px; width: 96%">
    <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let scontrino of allergeniFiltrati; index as indexOfelement;">
      <allergyCard (click)="cliccoAllergene(indexOfelement)" class="col-12 col-md-12" style=" padding-left: 15px; margin-left: 0px; padding-right: 0px;" [nome]="scontrino.allergene" [selezione]="scontrino.selezionato" ></allergyCard>
    </div>
  </div>
</div>

<!--div class="container" style="padding-top: 20px">
  <div class="row" style="padding-top: 0%; padding-left: 0px" *ngFor="let scontrino of scontrini">
    <categorieCard class="col-12 col-md-12" style=" padding-left: 0px; margin-left: 0px; padding-right: 0px;" [nome]="scontrino.nome" [luogo]="scontrino.luogo" [link]="scontrino.link" [costo]="scontrino.prezzo" ></categorieCard>
  </div>
</div-->
