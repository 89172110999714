import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'QRCodeScanner',
  styles: [`

    .options .wrap {
      padding: 1rem;
      text-align: center;
      background-color: white;
      border-radius: 12px;
      height: 170px;
      width: 92%;
      margin-left: 4%;
      max-height: 170px;
      object-fit: fill;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 1px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 2px 4px RGB(210, 210, 210);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);
      margin-bottom: 8px;

    }

    .row {
      display: block;

    }




    .blockico {
      margin-top: -50px;
      border-radius: 50px;
      height: 65px;
      width: 65px;

      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 1px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 2px 4px RGB(210, 210, 210);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);
    }

    h4{
      margin-bottom: 0px;
    }

  `],
  template: `

    <div *ngIf="!scannerEnabled" class="container-fluid" >
      <div class="row options">
        <div class="col-xs-12 ">
          <div class="wrap">
            <a>
              <!--img class="blockico" [src]=link>
              <h4 style="font-size: 14px; vertical-align: 0px; padding-bottom: 0px; font-weight: bolder; margin-bottom: 0px">{{title}}</h4>
              <h4 style="font-size: 12px; vertical-align: 0px; padding-bottom: 0px; font-weight: lighter;">{{subtitle}}</h4-->
              <img rel="preload" class="" src="https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/account%2Fqrcode.png?alt=media&token=9fc9a7ae-e9b8-4108-b2f3-24845609e10d" style="margin-bottom: 5px; margin-top: 5px; width: 40%">
            </a>
          </div>
        </div>
      </div>
    </div>

  `
})
export class QRCodeScannerComponent {

  @Input() link = 'https://firebasestorage.googleapis.com/v0/b/deweats.appspot.com/o/cibo%2Fjpeg%2Fd5f6b64a-81c8-41e1-a36a-01847634.jpg?alt=media&token=4e30f9c6-e183-4879-8dbc-4593ced7e4ca' ;
  @Input() title ;
  @Input() subtitle ;
  @Input() scannerEnabled;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    //console.log('ciaociaociaociao');
  }
  /**
   * Simulate logout
   */
  // tslint:disable-next-line:typedef
  logout(): void{
    // this.authService.logout();
    this.router.navigateByUrl('login');
  }
}
