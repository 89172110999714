import {Component, Input} from '@angular/core';
import { AuthStore } from '../../shared/services/authentication/auth.store';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/authentication/auth.service';

/**
 * This component includes several ways to protect DOM:
 * by injecting token, by using attributes and structural components
 */
@Component({
  selector: 'paymentHeader',
  styles: [`

    h4 {
      margin-bottom: 0px;
      color: white;
      margin-left: 3%;
    }

    .block {
      width: 100%;
      height: 100px;
      margin: 0px;
      margin-bottom: 1px;
      display: inline-flex;
      background: #bfbfbf;
      border-radius: 0px;
      font-size: 16px;
      align-items: center;
      justify-content: left;
      font-weight: bold;
      color: white;

      border-radius: 0px;
      border: solid 0px #0000000D;
      /* Add shadows to create the "card" effect */
      transition: 0.3s;
      -moz-box-shadow: 0px 0px 6px RGB(181, 181, 181);
      box-shadow: 0px 0px 6px RGB(181, 181, 181);
      -webkit-box-shadow: 0px 4px 4px RGB(220, 220, 220);
      -o-box-shadow: 0px 1px 6px RGB(181, 181, 181);


    }

    #store-locator {
      color: black;
    }



  `],
  template: `

    <div id="store-locator" class="block" (click)="clicco()">
      <div class="container" style="text-align: left">
        <h4 style="margin-top: 10px"><b>Credito</b></h4>
        <h4 ><p>0,00€</p></h4>
      </div>
      <button style="width: 20%; border-radius: 15px; margin-right: 15px; border: 0px; background-color: #e7e7e7"><p
        style="font-weight: lighter; margin: 4px; padding: 0px; font-size: 15px">Ricarica</p></button>
    </div>
  `
})
export class paymentHeader {

  @Input() messaggio1 ;
  @Input() messaggio2 ;
  @Input() luogo ;
  @Input() link ;
  @Input() costo ;

  constructor(
    // public authService: AuthService,
    private router: Router
  ) {}


  clicco(): void
  {
    console.log('porcodio');
  }
  /**
   * Simulate logout
   */


}
